/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Checkbox, Dropdown, FileDropzone, Heading, Link, Text, VerticalLayout,
} from "@fleet.co/tarmac";

import PhoneInput from "src/components/utils/PhoneInput";
import { useUserContext } from "src/contexts/UserContext";
import { downloadFileBlob, fileChecking, imgExtensions } from "src/tools/FilesHelpers";

import filesNiceName from "../filesNiceName";
import SignatoriesOptions from "./SignatoriesOptions";
import InsuranceInfoForm from "./InsuranceInfoForm";

const SignatoryChoice = (props) => {
  const {
    signatory, setStep, submitRole, getSignatoryInfo, isNew, isOrderMonitoring, signatoryInfos, setSignatoryInfos, setIsPhoneValid,
  } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const [signatories, setSignatories] = useState([]);
  const [files, setFiles] = useState({
    SIGNATORY_ID: null,
    SIGNATORY_ID_VERSO: null,
    IBAN: null,
    SIGNING_AUTHORITY: null,
  });
  const [showFiles, setShowFiles] = useState(false);
  const [error, setError] = useState(null);
  const companyFilesList = ["IBAN", "BALANCE_SHEET"];

  const getSignatories = async () => {
    const allEmployees = await currentUser.api.getUsers();
    const vipEmployees = allEmployees.data.filter((e) => e.roles.find((r) => r.role === "LEGAL_REPRESENTATIVE" || r.role === "ADMIN"));

    setSignatories(vipEmployees);
  };

  const downloadFile = async (file) => {
    const response = await currentUser.api.getFile(file.id);

    downloadFileBlob(response.data, (file.original_name || file.url));
  };

  const downloadModel = () => {
    let fileUrl;

    if (currentUser.company.country === "France") {
      fileUrl = "/documents/FLEET - Template - Pouvoir de signature.docx";
    } else {
      fileUrl = "/documents/FLEET - Template - Power of Attorney.docx";
    }

    window.open(fileUrl, "_self", "noopener noreferrer");
  };

  const deleteFile = async (file, fileType) => {
    await currentUser.api.deleteFile(file.id);
    setFiles((prevFiles) => ({ ...prevFiles, [fileType]: undefined }));
  };

  const submitFile = async (e, type) => {
    const errMsg = fileChecking(e.target.files[0], imgExtensions);

    if (errMsg) {
      setError(errMsg);

      return;
    }
    setError(null);

    const formData = new FormData();

    formData.append("type", type);
    formData.append("status", "TO_EXAMINE");
    if (!companyFilesList.includes(type)) {
      formData.append("user_id", signatory.id);
    }
    formData.append("file_contents", e.target.files[0]);
    const { data: newFile } = await currentUser.api.addFile(formData);

    setFiles((prevFiles) => ({ ...prevFiles, [type]: newFile }));
  };

  const handlePhoneChange = (phone, phone_country) => {
    setSignatoryInfos((prevInfo) => ({ ...prevInfo, phone, phone_country }));
  };

  const onInputChange = (name, value) => {
    setSignatoryInfos((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  useEffect(() => {
    getSignatories();
    if (currentUser.company?.country === "Spain") submitRole();
  }, []);

  useEffect(() => {
    const iban = currentUser.company.files.find((file) => file.type === "IBAN");
    const balanceSheet = currentUser.company.files.find((file) => file.type === "BALANCE_SHEET");
    const idRecto = signatory?.files.find((file) => file.type === "SIGNATORY_ID");
    const idVerso = signatory?.files.find((file) => file.type === "SIGNATORY_ID_VERSO");
    const signingAuthority = signatory?.files.find((file) => file.type === "SIGNING_AUTHORITY");

    const requiredFiles = {
      IBAN: iban,
      SIGNATORY_ID: idRecto,
      SIGNATORY_ID_VERSO: idVerso,
      BALANCE_SHEET: balanceSheet,
    };

    if (currentUser.company?.country.toLowerCase() !== "spain") {
      requiredFiles.SIGNING_AUTHORITY = signingAuthority;
    }

    setFiles(requiredFiles);
  }, [signatory, currentUser]);

  useEffect(() => {
    if (signatory?.roles.find((r) => r.role === "LEGAL_REPRESENTATIVE")) {
      if ((!files.IBAN || !files.SIGNATORY_ID) || isNew || isOrderMonitoring) {
        setShowFiles(true);
      } else {
        setShowFiles(false);
      }
    } else if (!files.IBAN || !files.SIGNATORY_ID
      || !files.SIGNING_AUTHORITY || isNew || isOrderMonitoring) {
      setShowFiles(true);
    } else {
      setShowFiles(false);
    }
  }, [signatory?.id]);

  const isLR = signatory?.roles.find((r) => r.role === "LEGAL_REPRESENTATIVE");

  return signatory && (
    <VerticalLayout gap="16" isScrollable={showFiles}>
      <VerticalLayout gap="8">
        <Text>
          {currentUser.company.country !== "Spain" && (
            <Trans>
              Pour valider votre commande vous devez
              {" "}
              <strong>définir le signataire du contrat</strong>
              .
              Merci de bien valider son identité en nous fournissant les
              pièces justificatives nécessaires.
            </Trans>
          )}
          {currentUser.company.country === "Spain" && (
            <Trans>
              Vous devez
              {" "}
              <strong>être le représentant légal </strong>
              pour valider votre commande. Veuillez valider votre identité en nous fournissant les pièces justificatives nécessaires.
            </Trans>
          )}
        </Text>
      </VerticalLayout>
      <Heading size="s">{t("Signataire")}</Heading>
      <Dropdown
        value={`${signatory.firstName} ${signatory.lastName}`}
        content={(
          <SignatoriesOptions
            goAddView={() => setStep("add")}
            signatories={signatories}
            getSignatoryInfo={getSignatoryInfo}
            isOrderMonitoring={isOrderMonitoring}
          />
        )}
      />
      <PhoneInput
        onChange={handlePhoneChange}
        name="phone"
        value={signatoryInfos.phone}
        country={signatoryInfos.phone_country}
        setIsPhoneValid={setIsPhoneValid}
      />
      <InsuranceInfoForm signatoryInfos={signatoryInfos} onChange={onInputChange} />
      {showFiles && (
        <VerticalLayout gap={8}>
          <Checkbox
            checked={isLR}
            onClick={submitRole}
          >
            {t("S'agit-il du représentant légal ?")}
          </Checkbox>
          <Heading size="s">{t("Ajouter les pièces justificatives obligatoires")}</Heading>
          <VerticalLayout gap="8">
            {error && <Text size="s" variant="danger">{t(error)}</Text>}
            <Text muted size="s">{t("Entreprise")}</Text>

            {Object.keys(files).map((fileName, i) => (
              !companyFilesList.includes(fileName) ? null
                : (
                  <FileDropzone
                    key={i}
                    selectFile={(e) => submitFile(e, fileName)}
                    isSmall
                    width="100%"
                    files={files[fileName]}
                    acceptedExtensions={imgExtensions.join(", ")}
                    onDownload={downloadFile}
                    onDelete={(file) => deleteFile(file, fileName)}
                    text={t(filesNiceName[fileName])}
                    label={t(filesNiceName[fileName])}
                  />
                )
            ))}
            <Text muted size="s">{t("Pièces d'identité du signataire")}</Text>
            {Object.keys(files).map((fileName, i) => (
              (fileName === "SIGNING_AUTHORITY" && isLR) || companyFilesList.includes(fileName) ? null
                : (
                  <FileDropzone
                    key={i}
                    selectFile={(e) => submitFile(e, fileName)}
                    isSmall
                    width="100%"
                    files={files[fileName]}
                    acceptedExtensions={imgExtensions.join(", ")}
                    onDownload={downloadFile}
                    onDelete={(file) => deleteFile(file, fileName)}
                    text={t(filesNiceName[fileName])}
                    label={t(filesNiceName[fileName])}
                  />
                )
            ))}
            {!isLR && currentUser.company?.country !== "Spain" && (
              <Text muted size="xs">
                <Trans>
                  Un pouvoir de signature est nécessaire pour déléguer la signature
                  du contrat de location.
                  {" "}
                  <Link onClick={downloadModel} span="true">Téléchargez ici</Link>
                  {" "}
                  le modèle et ajoutez-le une fois signé.
                </Trans>
              </Text>
            )}
          </VerticalLayout>
        </VerticalLayout>
      )}

    </VerticalLayout>
  );
};

export default SignatoryChoice;
