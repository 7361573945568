import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert, Button, Heading, HorizontalSeparator, Label, LabeledTextField, Modal, Search, Select, VerticalLayout,
} from "@fleet.co/tarmac";

import PhoneInput from "src/components/utils/PhoneInput";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import PlaceService from "src/tools/PlaceService";
import OutsideClickHandler from "src/components/utils/OutsideClickHandler";
import AddressSearchResults from "./CompanyAddressesSuggestions";

const CompanyAddressesModal = (props) => {
  // PROPS & CONTEXT
  const { onSave, address, onClose } = props;
  const { user: currentUser } = useUserContext();
  const { addToast } = useToastContext();

  const { t } = useTranslation();

  // GENERAL STATES
  const [errors, setErrors] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  // INITIALISATION STATES & VARIABLES
  const initialAddress = {
    label: address?.label || "",
    address1: address?.address1 || "",
    address2: address?.address2 || "",
    city: address?.city || "",
    zip: address?.zip || "",
    country: address?.country || "",
    firstName: address ? address.firstName : currentUser.firstName || "",
    lastName: address ? address.lastName : currentUser.lastName || "",
    phone: address ? address.phone : currentUser.phone || null,
    phone_country: address ? address.phone_country : currentUser.phone_country || null,
  };
  const [addressInfo, setAddressInfo] = useState(initialAddress);
  const [suggestions, setSuggestions] = useState([]);
  const [isAddressSearchResultsVisible, setIsAddressSearchResultsVisible] = useState(false);

  const authorizedCountries = [
    { label: t("France"), value: "France" },
    { label: t("Portugal"), value: "Portugal" },
    { label: t("Espagne"), value: "Spain" },
    { label: t("Italie"), value: "Italy" },
    { label: t("Royaume-Uni"), value: "United Kingdom" },
    { label: t("Allemagne"), value: "Germany" },
    { label: t("Luxembourg"), value: "Luxembourg" },
    { label: t("Pays-Bas"), value: "Netherlands" },
    { label: t("Suisse"), value: "Switzerland" },
    { label: t("Belgique"), value: "Belgium" },
    { label: t("Canada"), value: "Canada" },
    { label: t("Autre"), value: "Other" },
  ];

  const placeService = new PlaceService();

  // FUNCTIONS
  // Close modal (cancel)
  const onCloseAddressModal = () => {
    setErrors("");
    onClose();
  };

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAddressInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handlePhoneChange = (phone, countryPhone) => {
    setAddressInfo((prevInfo) => ({ ...prevInfo, phone, phone_country: countryPhone }));
  };

  const handleSearch = async (e) => {
    setAddressInfo({
      ...addressInfo,
      address1: e.target.value,
    });
    if (addressInfo.address1?.length < 5) {
      clearSuggestions();

      return;
    }
    const suggestionsFromApi = await placeService.getPlaceIdFromText(e.target.value);

    if (!isAddressSearchResultsVisible) {
      setIsAddressSearchResultsVisible(true);
    }

    setSuggestions(suggestionsFromApi);
  };

  const handleSelectCountry = (e) => {
    const selectedCountry = e.label;

    setAddressInfo((prevInfo) => ({ ...prevInfo, country: selectedCountry }));
  };

  // Handle address select in results box
  const handleSelect = async (suggestion) => {
    const placeId = suggestion.place_id;
    const addressFromApi = await placeService.getAddressForPlaceId(placeId);

    setAddressInfo({
      ...addressInfo,
      ...addressFromApi,
      address1: suggestion.structured_formatting.main_text || "",
    });

    clearSuggestions();
  };

  // Check if modal button should be disabled
  const buttonDisabled = () => {
    if (
      addressInfo.label?.trim() === ""
      || addressInfo.address1?.trim() === ""
      || addressInfo.zip?.trim() === ""
      || addressInfo.city?.trim() === ""
      || addressInfo.country?.trim() === ""
      || addressInfo.lastName?.trim() === ""
      || addressInfo.firstName?.trim() === ""
      || addressInfo.phone?.trim() === ""
      || !addressInfo.phone_country
      || JSON.stringify(addressInfo) === JSON.stringify(initialAddress)
    ) {
      return {
        message: t("Merci de remplir tous les champs obligatoires (*)"),
      };
    }

    return "";
  };

  // Create a new address or update it
  const saveChanges = async (e) => {
    e.preventDefault();
    try {
      const addressConfig = {
        address1: addressInfo.address1,
        address2: addressInfo.address2,
        city: addressInfo.city,
        zip: addressInfo.zip,
        country: addressInfo.country,
        label: addressInfo.label,
        firstName: addressInfo.firstName,
        lastName: addressInfo.lastName,
        phone: addressInfo.phone,
        phone_country: addressInfo.phone_country,
      };

      if (addressInfo.phone && !isPhoneValid) {
        addToast(t("Le numéro de téléphone n'est pas valide"));

        return;
      }

      if (address) {
        // MODIFY ADDRESS FROM COCKPIT
        await currentUser.api.modifyAddress(address.id, addressConfig);
        addToast(t("Adresse {{ addressName }} modifiée avec succès", { addressName: addressConfig.label }), "success");
        onSave();
      } else {
        // ADD ADDRESS FROM COCKPIT
        const newAddress = await currentUser.api.addAddress(addressConfig);

        addToast(t("Adresse {{ addressName }} créée avec succès", { addressName: addressConfig.label }), "success");
        onSave(newAddress.data.id);
      }

      onCloseAddressModal();
    } catch (e) {
      addToast(t(e.response.data.message));
    }
  };

  return (
    <Modal
      title={address && Object.entries(address).length > 0 ? t("Mettre à jour une adresse") : t("Ajouter une adresse")}
      primaryButton={(
        <Button fullWidth onClick={saveChanges} data-cy="Enregistrer" disabled={buttonDisabled() !== ""}>
          {t("Enregistrer")}
        </Button>
      )}
      closeModal={onCloseAddressModal}
    >
      {errors && <Alert>{errors}</Alert>}
      <VerticalLayout gap="24" isScrollable>
        <LabeledTextField
          name="label"
          data-cy="shippingLabel"
          value={addressInfo.label || ""}
          onChange={handleChange}
          indication="*"
          placeholder={t("Siège social")}
        >
          {t("Intitulé de l'adresse")}
        </LabeledTextField>
        <VerticalLayout gap="16">
          {/* If modal intend to modify an existing address, input field is standard */}
          {address && Object.entries(address).length > 0 ? (
            <LabeledTextField
              autoComplete="off"
              placeholder={t("Entrez une adresse")}
              data-cy="shippingAdress"
              name="address1"
              value={addressInfo.address1 || ""}
              onChange={handleChange}
              maxLength="40"
            />
          ) : (
            // If modal intend to create a new address, the "search address" results box is displayed
            <OutsideClickHandler action={() => setIsAddressSearchResultsVisible(false)}>
              <VerticalLayout>
                <Label indication="*">{t("Adresse")}</Label>
                <Search
                  name="address1"
                  placeholder={t("Rechercher une adresse")}
                  value={addressInfo.address1}
                  autoComplete="off"
                  onChange={handleSearch}
                  data-cy="shippingAddress"
                  style={{ width: "100%" }}
                  maxLength="40"
                />
                {(suggestions.length > 0 && isAddressSearchResultsVisible)
                  && (
                    <AddressSearchResults suggestions={suggestions} onSelect={handleSelect} />
                  )}
              </VerticalLayout>
            </OutsideClickHandler>
          )}

          <LabeledTextField
            data-cy="address2"
            name="address2"
            value={addressInfo.address2 || ""}
            onChange={handleChange}
            placeholder={t("Batiment C, 3er étage")}
            maxLength="60"
          >
            {t("Complément d'adresse")}
          </LabeledTextField>
          <LabeledTextField
            name="zip"
            indication="*"
            data-cy="shippingPostalCode"
            value={addressInfo.zip || ""}
            onChange={handleChange}
            placeholder="75008"
          >
            {t("Code postal")}
          </LabeledTextField>
          <LabeledTextField
            name="city"
            indication="*"
            data-cy="shippingCity"
            value={addressInfo.city || ""}
            onChange={handleChange}
            placeholder="Paris"
          >
            {t("Ville")}
          </LabeledTextField>
          <VerticalLayout>
            <Label indication="*">{t("Pays")}</Label>
            <Select
              name="country"
              data-cy="shippingCountry"
              value={addressInfo.country || ""}
              onSelect={handleSelectCountry}
              placeholder={t("Choisir un pays")}
              options={authorizedCountries}
            />
          </VerticalLayout>
        </VerticalLayout>
        <VerticalLayout gap="16">
          <Heading size="s">{t("Contact livraison")}</Heading>
          <HorizontalSeparator />
          <LabeledTextField
            name="firstName"
            indication="*"
            data-cy="shippingFirstName"
            value={addressInfo.firstName || ""}
            onChange={handleChange}
            placeholder="Jean"
          >
            {t("Prénom")}
          </LabeledTextField>
          <LabeledTextField
            name="lastName"
            indication="*"
            data-cy="shippingLastName"
            value={addressInfo.lastName || ""}
            onChange={handleChange}
            placeholder="Dupont"
          >
            {t("Nom")}
          </LabeledTextField>
          <VerticalLayout>
            <Label indication="*">{t("Téléphone")}</Label>
            <PhoneInput
              data-cy="shippingPhoneNumber"
              value={addressInfo.phone}
              country={addressInfo.phone_country}
              onChange={handlePhoneChange}
              setIsPhoneValid={setIsPhoneValid}
            />
          </VerticalLayout>
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default CompanyAddressesModal;
