import React from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  ClickableCard,
  Heading,
  HorizontalLayout,
  Icon,
  Link,
  Tag,
  Text,
  VerticalLayout,
  VerticalSeparator,
} from "@fleet.co/tarmac";
import { faClock, faLaptopMobile } from "@fortawesome/pro-regular-svg-icons";

import { useUserContext } from "src/contexts/UserContext";
import tagColorsAndNames from "src/data/tagColorsAndNames.json";

import styles from "./ListCard.module.scss";

const EmployeeClickableCard = (props) => {
  const {
    employee, buttonType, buttonLabel, onButtonClick, ...rest
  } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();

  let arrivalDate = null;

  if (employee?.arrival_date) {
    arrivalDate = new Date(employee.arrival_date);
    arrivalDate = arrivalDate.toLocaleDateString(currentUser.language, { dateStyle: "long" });
  }

  const initials = `${employee?.firstName ? employee.firstName[0] : ""}${employee?.lastName ? employee.lastName[0] : ""}`;

  let avatarColor;

  if (employee && !employee.photo) {
    const idx = employee.id ? employee.id % tagColorsAndNames.length
      : Math.floor(Math.random() * tagColorsAndNames.length);

    avatarColor = tagColorsAndNames[idx].color;
  }

  return (
    <ClickableCard
      onButtonClick={onButtonClick}
      buttonLabel={buttonLabel}
      buttonType={buttonType}
      {...rest}
    >
      <HorizontalLayout gap="8" className={styles.cardContent}>
        <Avatar
          letters={initials.toUpperCase()}
          url={employee?.photo_url ? employee.photo_url : null}
          color={avatarColor}
        />
        <VerticalLayout gap="8">
          <Heading className={styles.overflowHeading} size="XS">
            {`${employee?.firstName} ${employee?.lastName}`}
          </Heading>
          <HorizontalLayout gap="8" className={styles.employeeInfo}>
            {employee?.tags?.length > 0 && (
              <>
                {employee.tags.map((tag) => (
                  <Tag key={tag.id} filled small color={tag.color} label={tag.label} />
                ))}
                <VerticalSeparator />
              </>
            )}
            {employee?.position && (
              <>
                <Text size="M">{employee.position}</Text>
                <VerticalSeparator />
              </>
            )}
            {employee?.used_devices?.length > 0 && (
              <>
                <HorizontalLayout gap="8">
                  <Icon size="S" icon={faLaptopMobile} />
                  <Text size="M">{employee.used_devices.length}</Text>
                </HorizontalLayout>
                <VerticalSeparator />
              </>
            )}
            {employee?.arrival_date && new Date(employee.arrival_date) > Date.now() && (
              <HorizontalLayout gap="8">
                <Icon size="S" icon={faClock} />
                {arrivalDate && (
                  <Text size="M">
                    {t("Arrivée le")}
                    {" "}
                    {arrivalDate}
                  </Text>
                )}
              </HorizontalLayout>
            )}
            {employee.id && (
              <div className={styles.actionLinkMobile}>

                <Link onClick={onButtonClick} iconSide="right">{t("Voir en détails")}</Link>
              </div>
            )}
          </HorizontalLayout>
        </VerticalLayout>
      </HorizontalLayout>
    </ClickableCard>
  );
};

export default EmployeeClickableCard;
