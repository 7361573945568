/* eslint-disable camelcase */
import React, {
  useCallback, useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Heading,
  HorizontalLayout,
  HorizontalSeparator,
  Modal,
  Text,
  VerticalLayout,
} from "@fleet.co/tarmac";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";

import useDeviceType from "src/hooks/useDeviceType";
import MoneyFormat from "src/tools/MoneyFormat";
import offersList from "src/data/offersList";
import ShoppingCartModalItem from "./ShoppingCartModalItem";

const ShoppingCartModal = (props) => {
  const {
    onClose,
    onSave,
    nextStep,
    order,
    reloadOrder,
  } = props;
  const deviceType = useDeviceType();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addToast } = useToastContext();
  const [orderInfo, setOrderInfo] = useState({});

  const reloadOrderInfo = useCallback(async () => {
    const fetchedOrder = await user?.api.getOrder(order.id);

    setOrderInfo(fetchedOrder.data);
  }, [user?.api, setOrderInfo, order]);

  useEffect(() => {
    reloadOrderInfo();
  }, []);

  const cartItems = orderInfo?.devices?.reduce(
    (result, item) => ({
      ...result,
      [item.product_id]: {
        ...result[item.product_id],
        [item.keyboard_layout]: result[item.product_id]
          ? [...(result[item.product_id][item.keyboard_layout] || []), item]
          : [item],
      },
    }),
    {},
  ) || {};

  const totalMonthlyFinalRent = orderInfo.rent || 0;

  const totalMonthlyBaseRent = orderInfo?.devices?.reduce(
    (sum, device) => sum + Number(device.base_price),
    0,
  ).toFixed(2);

  const companyActiveOffer = user.getCompanyActiveOffer();

  const companyUsableReferralCredit = user.getCompanyUsableReferralCredit();

  const handleSave = useCallback(async () => {
    try {
      if (user.companyInfoCompleted()) {
        await user.api.checkCompanySelfEmployeed();
      }

      if (totalMonthlyBaseRent >= 19) {
        nextStep();
      } else {
        addToast(t("Minimum de commande : {{ minAmount }}", { minAmount: order.rent_currency === "GBP" ? "£19" : "19 €" }));
      }
    } catch (e) {
      addToast(t("Notre service n'est malheureusement pas disponible pour les particuliers ou entreprises individuelles"));
      console.error(e);
    }
  }, [addToast, nextStep, t, totalMonthlyBaseRent, user]);

  const offerInfo = offersList.find((o) => o.name === companyActiveOffer?.type);

  return (orderInfo && (
    <Modal
      closeModal={onClose}
      title={t("Panier")}
      primaryButton={(
        <Button fullWidth onClick={handleSave} disabled={!orderInfo?.devices?.length} data-cy="Valider mon panier">
          {t("Valider mon panier")}
        </Button>
      )}
      secondaryButton={(
        <Button fullWidth onClick={onSave}>
          {t("Ajouter des appareils")}
        </Button>
      )}
      {...(deviceType !== "desktop" && { buttonContainerProps: { flexWrap: "wrap" } })}
    >
      <VerticalLayout gap={24} fullHeight justifyContent="space-between">
        <VerticalLayout gap={8} isScrollable>
          {Object.keys(cartItems).map(
            (productId) => (
              Object.keys(cartItems[productId]).map((keyboard) => (
                <ShoppingCartModalItem
                  device={cartItems[productId][keyboard][0]}
                  keyboard={keyboard}
                  productId={productId}
                  deviceNumber={cartItems[productId][keyboard].length}
                  orderInfo={orderInfo}
                  reloadOrder={reloadOrder}
                  reloadOrderInfo={reloadOrderInfo}
                />
              ))
            ),
          )}
        </VerticalLayout>

        <VerticalLayout gap={24}>
          <HorizontalSeparator />
          <VerticalLayout gap={32}>
            {companyActiveOffer || (order && order.referrer_id) || companyUsableReferralCredit ? (
              <Text size="S" textAlign="center" variant="accent" bold>
                {t(offerInfo.text)}
              </Text>
            ) : null}
            <VerticalLayout gap={8}>
              {orderInfo.devices?.length ? (
                <HorizontalLayout gap={8}>
                  <Text muted>
                    {t(`{{cartItemsNb}} article${orderInfo.devices.length > 1 ? "s" : ""}`, {
                      cartItemsNb: orderInfo.devices.length,
                    })}
                  </Text>
                </HorizontalLayout>
              ) : null}
              <HorizontalLayout justifyContent="space-between">
                <Text bold>{t("Sous-total :")}</Text>
                <Text>
                  <MoneyFormat value={totalMonthlyBaseRent} />
                </Text>
              </HorizontalLayout>
              <HorizontalLayout justifyContent="space-between">
                <Text bold>{t("Livraison :")}</Text>
                <Text>{t("Offerte")}</Text>
              </HorizontalLayout>
              <HorizontalLayout justifyContent="space-between">
                <Text bold>{t("Loyer :")}</Text>
                <Heading size="XS">
                  <MoneyFormat value={totalMonthlyFinalRent} />
                  {t("/mois HT")}
                </Heading>
              </HorizontalLayout>
            </VerticalLayout>
          </VerticalLayout>
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  ));
};

export default ShoppingCartModal;
