import React from "react";
import { Button, Modal } from "@fleet.co/tarmac";
import { Trans, useTranslation } from "react-i18next";
import styles from "./LeaserInvoicesModal.module.scss";

const LeaserInvoicesModal = (props) => {
  // PROPS & CONTEXT
  const { leaserInfo, onClose } = props;
  const { t } = useTranslation();
  const leaserName = leaserInfo.name;

  const leasersText = {
    "NBB Lease": {
      modalContent: (
        <>
          <p>
            {t(`Un échéancier valant facture vous a été envoyé par mail (ou par voie postale lorsque l"adresse mail
          n"était pas disponible) lors de la mise en place du contrat.`)}
          </p>
          <br />
          <p>
            {t(
              `Si vous souhaitez recevoir vos factures détaillées, nous vous invitons à contacter directement le
          service client {{leaserName}} :`,
              { leaserName },
            )}
          </p>
        </>
      ),
      mailContact: "service-client@nbb-lease.fr",
      phoneContact: "05.40.08.32.40",
    },
    Leasecom: {
      modalContent: (
        <>
          <p>
            {t(`Un échéancier valant facture vous a été envoyé par mail (ou par voie postale lorsque l"adresse mail
          n"était pas disponible) lors de la mise en place du contrat.`)}
          </p>
          <br />
          <p>
            {t(
              `Si vous souhaitez recevoir vos factures détaillées, nous vous invitons à contacter directement le
          service client {{leaserName}} :`,
              { leaserName },
            )}
          </p>
        </>
      ),
      mailContact: "serviceclient@leasecom.fr",
      phoneContact: "08.10.81.80.90",
    },
    Locam: {
      modalContent: (
        <>
          <p>
            {t(`Un échéancier valant facture vous a été envoyé par mail (ou par voie postale lorsque l"adresse mail
          n"était pas disponible) lors de la mise en place du contrat.`)}
          </p>
          <br />
          <p>
            {t(
              `Si vous souhaitez recevoir vos factures détaillées, nous vous invitons à contacter directement le
          service client {{leaserName}} :`,
              { leaserName },
            )}
          </p>
        </>
      ),
      mailContact: "locam.client@locam.fr",
      phoneContact: "04.77.92.59.24",
    },
    FranFinance: {
      modalContent: (
        <>
          <p>{t("Vos factures vous sont envoyées tous les mois par courrier.")}</p>
          <br />
          <p>
            {t(`Si vous souhaitez les recevoir par email, vous pouvez le préciser au moment de la signature de votre
                contrat, ou en nous contactant à l’adresse suivante :`)}
          </p>
        </>
      ),
      mailContact: "support@fleet.co",
    },
    Grenke: {
      modalContent: (
        <>
          <Trans>
            <p>
              Toutes vos factures sont disponibles sur
              {" "}
              <a href={leaserInfo.platformLink} target="blank">
                votre espace client
                {" "}
                {{ leaserName }}
              </a>
              .
            </p>
          </Trans>
          <br />
          <p>
            {t(`Une fois sur la plateforme, vous devrez renseigner vos identifiants et mot de passe pour vous connecter
                et accéder à vos factures.`)}
          </p>
        </>
      ),
    },
    BNP: {
      modalContent: (
        <>
          <Trans>
            <p>
              Toutes vos factures sont disponibles sur
              {" "}
              <a href={leaserInfo.platformLink} target="blank">
                votre espace client
                {" "}
                {{ leaserName }}
              </a>
              .
            </p>
          </Trans>
          <br />
          <p>
            {t(`Une fois sur la plateforme, vous devrez renseigner vos identifiants et mot de passe pour vous connecter
                et accéder à vos factures.`)}
          </p>
        </>
      ),
    },
  };

  return (
    <Modal
      title={t("Accèder à mes factures {{leaserName}}", { leaserName })}
      closeModal={onClose}
      secondaryButton={(
        <Button onClick={onClose}>
          {t("Fermer")}
        </Button>
      )}
    >
      <div className={styles.invoicesContent}>
        <div>
          {leasersText[leaserName].modalContent}
          {leasersText[leaserName].mailContact && (
            <div className={styles.clickableBox}>
              <div className={styles.clickableBoxContent}>
                {`${t("Par email")} : `}
                <span>{leasersText[leaserName].mailContact}</span>
                {leasersText[leaserName].phoneContact && (
                  <>
                    <br />
                    {`${t("Par téléphone")} : `}
                    <span>{leasersText[leaserName].phoneContact}</span>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default LeaserInvoicesModal;
