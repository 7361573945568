import React, { useState } from "react";
import {
  Button, HorizontalSeparator, Label, LabeledTextField, Modal, VerticalLayout,
} from "@fleet.co/tarmac";
import { useTranslation } from "react-i18next";
import PhoneInput from "src/components/utils/PhoneInput";
import EmployeeClickableCard from "src/components/cockpit/blocks/listCards/EmployeeClickableCard";

const EditEmployee = (props) => {
  const { employee, onSave, onClose } = props;
  const [editedEmployee, setEditedEmployee] = useState(employee);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const { t } = useTranslation();

  const saveEmployee = () => {
    onSave(editedEmployee);
    onClose();
  };

  const handlePhoneChange = (phone, countryPhone) => {
    setEditedEmployee({ ...editedEmployee, phone, country: countryPhone });
  };

  return (
    <Modal
      closeModal={onClose}
      title={t("Modification d'un employé")}
      primaryButton={(
        <Button fullWidth onClick={saveEmployee} disabled={editedEmployee.phone && !isPhoneValid}>
          {t("Mettre à jour le profil")}
        </Button>
      )}
    >
      <VerticalLayout gap={32}>
        <EmployeeClickableCard employee={employee} />
        <HorizontalSeparator />
        <VerticalLayout gap={16}>
          <LabeledTextField
            name="firstName"
            indication="*"
            value={editedEmployee.firstName}
            disabled
          >
            {t("Prénom")}
          </LabeledTextField>
          <LabeledTextField
            name="lastName"
            indication="*"
            value={editedEmployee.lastName}
            disabled
          >
            {t("Nom")}
          </LabeledTextField>
          <LabeledTextField
            name="email"
            indication="*"
            value={editedEmployee.email}
            onChange={(e) => setEditedEmployee({ ...editedEmployee, email: e.target.value })}
          >
            {t("Email")}
          </LabeledTextField>
          <VerticalLayout fullWidth>
            <Label>{t("Numéro de téléphone")}</Label>
            <PhoneInput
              value={editedEmployee.phone}
              onChange={handlePhoneChange}
              country={editedEmployee.phone_country}
              setIsPhoneValid={setIsPhoneValid}
            />
          </VerticalLayout>
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default EditEmployee;
