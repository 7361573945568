import React, { useCallback, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";
import {
  Button, Checkbox, Heading, HorizontalLayout, Link, Modal, SectionZone, Text, VerticalLayout,
} from "@fleet.co/tarmac";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import notifications from "src/data/notifications";

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_ADMIN":
      return {
        ...state,
        roles: {
          ...state.roles,
          admin: !state.roles.admin,
        },
        notifications: notifications.reduce((acc, cur) => ({ ...acc, [cur.value]: false }), {}),
      };
    case "TOGGLE_NOTIFICATION":
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload]: !state.notifications[action.payload],
        },
      };
    case "UNSELECT_ALL":
      return {
        ...state,
        notifications: notifications.reduce((acc, cur) => ({ ...acc, [cur.value]: false }), {}),
      };
    default:
      return state;
  }
};

const ManageRolesAndNoticesModal = ({ onClose, employee }) => {
  const { t } = useTranslation();
  const { user, reloadUser } = useUserContext();
  const { addToast } = useToastContext();

  const initialState = useMemo(() => ({
    roles: {
      admin: employee.roles.some((r) => r.role === "ADMIN"),
    },
    notifications: notifications.reduce((acc, cur) => ({ ...acc, [cur.value]: employee.notifications.some((n) => n.type === cur.value) }), {}),
  }), [employee]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const applyChanges = useCallback(async () => {
    const userData = {
      roles_add: state.roles.admin ? "ADMIN" : null,
      roles_del: state.roles.admin ? null : ["ADMIN"],
      notifications_add: Object.entries(state.notifications).filter(([, value]) => value).map(([key]) => key),
      notifications_del: Object.entries(state.notifications).filter(([, value]) => !value).map(([key]) => key),
    };

    try {
      await user.api.modifyUser(employee.id, userData);
      reloadUser();
      onClose();
    } catch (err) {
      addToast(t(err.response.data.message));
    }
  }, [state, user, employee, reloadUser, onClose, addToast, t]);

  return (
    <Modal
      closeModal={onClose}
      title={t("Modifier les permissions et notifications")}
      primaryButton={(
        <Button fullWidth onClick={applyChanges}>
          {t("Valider")}
        </Button>
  )}
      secondaryButton={(
        <Button fullWidth onClick={onClose}>
          {t("Fermer")}
        </Button>
  )}
    >
      <VerticalLayout gap="32" isScrollable>
        <VerticalLayout gap="16">
          <Heading size="S">{t("Permissions")}</Heading>
          <SectionZone padding="24">
            <VerticalLayout gap="16">
              <HorizontalLayout gap="16" alignItems="center">
                <Checkbox checked={state.roles.admin} onClick={() => dispatch({ type: "TOGGLE_ADMIN" })} />
                <VerticalLayout>
                  <Text size="M" bold>{t("Admin")}</Text>
                  <Text size="s" muted>{t("L'admin aura les mêmes accès Cockpit que vous, il pourra effectuer des demandes de locations Fleet et d'ajouter des employés.")}</Text>
                </VerticalLayout>
              </HorizontalLayout>
              <HorizontalLayout gap="16" alignItems="center">
                <Checkbox checked disabled />
                <VerticalLayout>
                  <Text size="M" bold>{t("Employee")}</Text>
                  <Text size="s" muted>{t("L'employé aura des accès limités au Cockpit et ne sera pas autorisé à effectuer des demandes de locations Fleet ni à ajouter des employés.")}</Text>
                </VerticalLayout>
              </HorizontalLayout>
            </VerticalLayout>
          </SectionZone>
          <HorizontalLayout justifyContent="space-between" alignItems="center">
            <Heading size="S">{t("Notifications")}</Heading>
            <Link onClick={() => dispatch({ type: "UNSELECT_ALL" })}>{t("Tout désélectionner")}</Link>
          </HorizontalLayout>
          <SectionZone padding="24">
            <VerticalLayout gap="16">
              {notifications.map((notification) => (
                <HorizontalLayout gap="16" alignItems="center">
                  <Checkbox disabled={!state.roles.admin} checked={state.notifications[notification.value]} onClick={() => dispatch({ type: "TOGGLE_NOTIFICATION", payload: notification.value })} />
                  <VerticalLayout>
                    <Text size="M" bold>{t(notification.title)}</Text>
                    <Text size="s" muted>{t(notification.description)}</Text>
                  </VerticalLayout>
                </HorizontalLayout>
              ))}
            </VerticalLayout>
          </SectionZone>
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default ManageRolesAndNoticesModal;
