import React from "react";
import { VerticalLayout } from "@fleet.co/tarmac";

import styles from "./ItemsNumberedList.module.scss";

const ItemsNumberedList = ({ children }) => (
  <VerticalLayout gap="16">
    {children.map((elem, index) => (
      <div className={styles.itemBlock} key={index}>
        <div className={styles.itemNumber}>{index + 1}</div>
        <div className={styles.itemContent}>{elem}</div>
      </div>
    ))}
  </VerticalLayout>
);

export default ItemsNumberedList;
