import React from "react";
import { VerticalLayout } from "@fleet.co/tarmac";
import styles from "./CompanyAddressesModal.module.scss";

const AddressSearchResults = ({ suggestions, onSelect }) => (
  <VerticalLayout className={styles.addressSearchResultsContainer}>
    {suggestions.map((suggestion, index) => (
      <div className={styles.addressSearchResults} key={index} onClick={() => onSelect(suggestion)} aria-hidden>
        <span>{suggestion.description}</span>
      </div>
    ))}
  </VerticalLayout>
);

export default AddressSearchResults;
