import React, { useState } from "react";
import {
  Button, Heading, LabeledTextField, Link, Text, VerticalLayout,
} from "@fleet.co/tarmac";
import { useTranslation } from "react-i18next";

import { useToastContext } from "src/contexts/ToastContext";

import AuthService from "src/components/auth/auth-service";

import styles from "../CockpitLogin.module.scss";

const ResetPassword = (props) => {
  const { backToLogin } = props;
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const service = new AuthService();

  const handleSubmit = async () => {
    setResetSent(true);
    // Update state before async call
    await service.requestToken(email);
    addToast(t("Un e-mail a été envoyé à {{ userEmail }} contenant des instructions pour réinitialiser votre mot de passe", { userEmail: email }), "success");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <>
      <Heading textAlign="center" component="h1" size="L">
        {t("Mot de passe oublié")}
      </Heading>

      {!resetSent && (
        <Text textAlign="center" className={styles.resetPassText}>
          {t("Vous avez oublié votre mot de passe ? Saisissez l'adresse e-mail pour réinitialiser votre mot de passe")}
        </Text>
      )}

      <VerticalLayout gap={16} alignItems="center" className={styles.formContainer}>
        <VerticalLayout gap={16} alignItems="center" className={styles.formInput}>
          <LabeledTextField
            name="email"
            type="text"
            placeholder={t("Email")}
            indication="*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
          >
            {t("E-mail professionnel")}
          </LabeledTextField>

          <Button type="submit" variant="primary" onClick={handleSubmit}>
            {t("Modifier mon mot de passe")}
          </Button>
        </VerticalLayout>

        <Link onClick={backToLogin}>
          {t("Revenir à la page de connexion")}
        </Link>
      </VerticalLayout>
    </>
  );
};

export default ResetPassword;
