import React from "react";
import { HorizontalLayout } from "@fleet.co/tarmac";

const LoadingSpinner = () => (
  <HorizontalLayout
    fullWidth
    fullHeight
    justifyContent="center"
    alignItems="center"
    style={{ height: "100vh" }}
  >
    <div className="lds-spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    {/* loader from https://loading.io/css/ */}
  </HorizontalLayout>
);

export default LoadingSpinner;
