import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  HorizontalLayout,
  Icon,
  Label,
  LabeledTextField,
  Link,
  Modal,
  Search,
  Text,
  VerticalLayout,
} from "@fleet.co/tarmac";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import PhoneInput from "src/components/utils/PhoneInput";
import EmployeeSelectableListCard from "src/components/cockpit/blocks/listCards/EmployeeSelectableListCard";
import DeviceUserClickableCard from "src/components/cockpit/blocks/listCards/DeviceUserClickableCard";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import { useConfirmModalContext } from "src/contexts/ConfirmModalContext";

import { employeeMatches } from "src/tools/SearchFilters";

import MissingSerialNumberDialog from "src/components/dialogs/missingSerialNumber/MissingSerialNumberDialog";
import styles from "./AssignModal.module.scss";

const AssignModal = (props) => {
  // PROPS & CONTEXT
  const { onSave, device, onClose } = props;
  const { user: currentUser } = useUserContext();
  const { setConfirmModal } = useConfirmModalContext();
  const { t } = useTranslation();

  // GENERAL STATES
  const [errors, setErrors] = useState("");

  // INITIALISATION STATES & VARIABLES
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [creatingUser, setCreatingUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const { addToast } = useToastContext();
  const [missingSerialNumberModal, setMissingSerialNumberModal] = useState(false);
  const [deviceWithoutSerialNumber, setDeviceWithoutSerialNumber] = useState(undefined);
  const [searchResults, setSearchResults] = useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;

    setCreatingUser({ ...creatingUser, [name]: value });
  };

  const onPhoneChange = (phone, country) => {
    setCreatingUser({ ...creatingUser, phone, phone_country: country });
  };

  const searchUsers = (search) => {
    if (search) {
      const filtered = currentUser.company.employees.filter(
        (employee) => employeeMatches(employee, search),
      );

      setFilteredUsersList(filtered);
      setSearchResults(true);
    } else {
      setFilteredUsersList(currentUser.company.employees);
      setSearchResults(false);
    }
  };

  const assignUser = async () => {
    let userId;

    if (isCreating) {
      if (!creatingUser.firstName.trim() || !creatingUser.lastName.trim() || !creatingUser.email.trim()) {
        const missingFields = [
          !creatingUser.firstName.trim() && "Prénom",
          !creatingUser.lastName.trim() && "Nom",
          !creatingUser.email.trim() && "E-Mail"]
          .filter(Boolean);

        addToast(t(`Veuillez renseigner tous les champs requis : ${missingFields.join(", ")}`));

        return;
      }

      if (!creatingUser.email.includes("@")) {
        addToast(t("Veuillez renseigner un email valide"));

        return;
      }

      try {
        await currentUser.api.checkUser({ email: creatingUser.email });
      } catch (err) {
        if (err.response.status === 409) {
          addToast(t("Un utilisateur avec cette addresse mail existe déjà"));

          return;
        }
        addToast(t("Une erreur est survenue"));

        return;
      }

      if (creatingUser.phone && !isPhoneValid) {
        addToast(t("Le numéro de téléphone n'est pas valide"));

        return;
      }

      const userProps = {
        firstName: creatingUser.firstName,
        lastName: creatingUser.lastName,
        email: creatingUser.email.trim(),
        phone: creatingUser.phone,
        phone_country: creatingUser.phone_country,
        roles_add: "EMPLOYEE",
      };

      const apiResponse = await currentUser.api.addUser(userProps);

      userId = apiResponse.data.id;
    } else if (selectedUser) {
      userId = selectedUser.id;
    } else {
      return;
    }

    try {
      const deviceResponse = await currentUser.api.modifyDevice(device.id, {
        user_id: userId,
        ...(device.usage_status === "SPARE" && { usage_status: "RUNNING" }),
      });

      onSave(deviceResponse.data, userId);
      const validStep = ["DELIVERED", "BDL_SENT", "RECEIVED", "FINALIZED", "CLOSED"];
      const missingSerialNumber = device.serial_number === null && device.source === "FLEET" && validStep.includes(device.status) && device.category === "COMPUTER";

      setDeviceWithoutSerialNumber(missingSerialNumber && device);
      if (missingSerialNumber) {
        setMissingSerialNumberModal(device);
      } else {
        onClose();
      }
    } catch (err) {
      console.error(err);
      setErrors(err.response.data.message || t("Action impossible à effectuer"));
    }
  };

  const moveToSpare = async () => {
    try {
      const deviceUpdated = await currentUser.api.modifyDevice(device.id, {
        user_id: null,
        usage_status: "SPARE",
      });

      if (onSave) {
        await onSave(deviceUpdated.data.id);
      }

      onClose();
      setConfirmModal(null);
    } catch (err) {
      console.error(err);
      setErrors(err.response.data.message || t("Action impossible à effectuer"));
    }
  };

  const confirmMoveToSpare = () => {
    setConfirmModal({
      onSubmit: moveToSpare,
      title: t("Mettre en réserve"),
      children: t(
        "Vous vous apprêtez à placer cet appareil en réserve. Il ne sera plus attribué. Souhaitez-vous poursuivre ?",
      ),
    });
  };

  useEffect(() => {
    setFilteredUsersList(currentUser.company.employees);
  }, [currentUser]);

  const noEquipedEmployees = filteredUsersList
    .filter((e) => (searchResults ? e?.id !== device?.user?.id : e?.used_devices.length === 0))
    .sort((a, b) => a.firstName?.localeCompare(b.firstName));

  return (
    <Modal
      closeModal={onClose}
      title={t("Attribuer à un employé")}
      primaryButton={(
        <Button fullWidth onClick={assignUser}>
          {t("Enregistrer")}
        </Button>
      )}
      secondaryButton={(
        <Button fullWidth onClick={confirmMoveToSpare}>
          {t("Mettre en réserve")}
        </Button>
      )}
    >
      {errors && <Alert onClick={() => setErrors("")}>{errors}</Alert>}
      {missingSerialNumberModal && (
        <MissingSerialNumberDialog
          setOpen={onClose}
          device={deviceWithoutSerialNumber}
          onSave={onSave}
        />
      )}
      <VerticalLayout gap="32" className={styles.container}>
        <VerticalLayout gap="8">
          <Label>{t("Attribution actuelle")}</Label>
          <DeviceUserClickableCard device={device} noCheckbox />
        </VerticalLayout>
        <VerticalLayout gap="32">
          {isCreating ? (
            <VerticalLayout gap="16">
              <HorizontalLayout gap="8" className={styles.justifyContent}>
                <Text>{t("Utilisateur existant ?")}</Text>
                <Link onClick={() => setIsCreating(false)}>{t("Sélectionner dans la liste")}</Link>
              </HorizontalLayout>
              <LabeledTextField onChange={onInputChange} name="firstName" value={creatingUser.firstName} indication="*">
                {t("Prénom")}
              </LabeledTextField>
              <LabeledTextField onChange={onInputChange} name="lastName" value={creatingUser.lastName} indication="*">
                {t("Nom")}
              </LabeledTextField>
              <LabeledTextField onChange={onInputChange} name="email" value={creatingUser.email} indication="*">
                {t("E-Mail")}
              </LabeledTextField>
              <VerticalLayout>
                <Label>{t("Numéro de téléphone")}</Label>
                <PhoneInput
                  onChange={onPhoneChange}
                  name="phone"
                  value={creatingUser.phone}
                  country={creatingUser.phone_country}
                  setIsPhoneValid={setIsPhoneValid}
                />
              </VerticalLayout>
            </VerticalLayout>
          ) : (
            <>
              <VerticalLayout gap="4">
                <Label>{t("À qui souhaitez-vous attribuer cet équipement ?")}</Label>
                <HorizontalLayout className={styles.justifyContent}>
                  <Search
                    value={searchUser}
                    onChange={(e) => {
                      searchUsers(e.target.value);
                      setSearchUser(e.target.value);
                    }}
                    placeholder={t("Rechercher un employé")}
                    style={{ width: "100%" }}
                  />
                  {
                    searchUser.length !== 0
                    && <Icon icon={faTimes} onClick={() => setSearchUser("")} style={{ cursor: "pointer", margin: "2px" }} />
                  }
                </HorizontalLayout>

              </VerticalLayout>
              <HorizontalLayout className={styles.justifyContent}>
                <Text>{t("Pas dans la liste ?")}</Text>
                <Link onClick={() => setIsCreating(true)}>{t("Ajouter un employé")}</Link>
              </HorizontalLayout>
              {selectedUser ? (
                <HorizontalLayout gap="8" className={styles.alignItems}>
                  <EmployeeSelectableListCard
                    noCheckbox
                    employee={selectedUser}
                    grow
                    isActive
                    noscroll
                  />
                  <Icon icon={faTimes} onClick={() => setSelectedUser(null)} style={{ cursor: "pointer" }} />
                </HorizontalLayout>
              ) : (
                <VerticalLayout gap="16" className={styles.listWrapper}>
                  {noEquipedEmployees
                    .map((e) => (
                      <EmployeeSelectableListCard
                        noCheckbox
                        onCardClick={() => setSelectedUser(e)}
                        employee={e}
                      />
                    ))}
                </VerticalLayout>

              )}
            </>
          )}
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default AssignModal;
