import React from "react";
import { useTranslation } from "react-i18next";
import { ConfirmModal } from "@fleet.co/tarmac";

import { useConfirmModalContext } from "src/contexts/ConfirmModalContext";

const ConfirmModalContainer = () => {
  const { confirmModal, setConfirmModal } = useConfirmModalContext();
  const { t } = useTranslation();

  if (confirmModal === null) {
    return "";
  }

  return (
    <ConfirmModal
      onClose={() => setConfirmModal(null)}
      primaryLabel={confirmModal.primaryLabel || t("Confirmer")}
      secondaryLabel={t("Annuler")}
      {...confirmModal}
    />
  );
};

export default ConfirmModalContainer;
