import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Alert,
  Button, Label, LabeledTextField, Modal, Select, Text, VerticalLayout,
} from "@fleet.co/tarmac";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import useDeviceType from "src/hooks/useDeviceType";

const StripeFormModal = ({ setOpen }) => {
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [accountantInfo, setAccountantInfo] = useState({});
  const [message, setMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToastContext();
  const deviceType = useDeviceType();

  const [addresses, setAddresses] = useState([]);
  const onInputChange = (e) => setAccountantInfo((prevInfo) => ({ ...prevInfo, [e.target.name]: e.target.value }));
  const onCountryChange = (option) => setAccountantInfo((prevInfo) => ({ ...prevInfo, country: option.value }));
  const onAddressChange = (option) => setAccountantInfo((prevInfo) => ({ ...prevInfo, address: option.value }));

  const countryOptions = useMemo(() => ([
    { label: t("France"), value: "FR", selected: accountantInfo.country === "FR" },
    { label: t("Espagne"), value: "ES", selected: accountantInfo.country === "ES" },
    { label: t("Allemagne"), value: "DE", selected: accountantInfo.country === "DE" },
  ]), [accountantInfo, t]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    if (!accountantInfo.name || !accountantInfo.country || !accountantInfo.address || !accountantInfo.email) {
      setMessage(t("Veuillez remplir tous les champs obligatoires"));
      setIsLoading(false);

      return;
    }

    if (!stripe || !elements) {
      setIsLoading(false);

      return;
    }

    const iban = elements.getElement(IbanElement);

    const { data: paymentInfo } = await currentUser.api.createPaymentIntent();

    const result = await stripe.confirmSepaDebitSetup(paymentInfo.client_secret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: accountantInfo.name,
          email: accountantInfo.email,
          address: {
            city: accountantInfo.address.city,
            country: accountantInfo.country,
            postal_code: accountantInfo.address.zip,
            line1: accountantInfo.address.address1,
          },
        },
      },
    });

    if (result.error) {
      let message;

      switch (result.error.type) {
        case "validation_error":
          message = t("Le numéro IBAN est invalide, veuillez réessayer");
          break;
        case "parameter_missing":
          message = t("Un paramètre est manquant, veuillez réessayer");
          break;
        default:
          message = t("Une erreur est survenue, veuillez réessayer");
      }

      setMessage(message);
    } else {
      try {
        await currentUser.api.createPaymentMethod({ paymentMethodStripeId: result.setupIntent.payment_method });
      } catch (err) {
        addToast(t("Une erreur est survenue"));
      }

      addToast(t("Mandat SEPA bien enregistré"), "success");
      setOpen(false);
    }

    setIsLoading(false);
  }, [accountantInfo, addToast, currentUser, elements, setOpen, stripe, t]);

  const ibanElementOptions = {
    supportedCountries: ["SEPA"],
    placeholderCountry: "FR",
    style: { base: { fontSize: "14px" }, empty: { color: "#7E7E7E" } },
  };

  const defaultCountryCode = useMemo(() => {
    switch (currentUser?.company.country) {
      case "France":
        return "FR";
      case "Spain":
        return "ES";
      case "Germany":
        return "DE";
      default:
        return "FR";
    }
  }, [currentUser]);

  useEffect(() => {
    setAccountantInfo({
      email: currentUser?.email,
      name: currentUser?.company.legal_name,
      country: defaultCountryCode,
      address: currentUser?.company.addresses.find((a) => a.is_default),
    });

    setAddresses(currentUser?.company.addresses.map((a) => ({ label: `${a.address1}, ${a.city}`, value: a })));
  }, [currentUser, defaultCountryCode]);

  const customIbanStyle = {
    border: "1px solid",
    padding: "8px 12px",
    borderRadius: "8px",
    height: "38px",
  };

  return (
    <Modal
      closeModal={() => setOpen(false)}
      title={t("Nouveau mandat SEPA")}
      primaryButton={(
        <Button onClick={handleSubmit} isLoading={isLoading} fullWidth>
          {t("Enregistrer")}
        </Button>
      )}
      secondaryButton={(
        <Button onClick={() => setOpen(false)} fullWidth>
          {t("Annuler")}
        </Button>
      )}
    >
      <VerticalLayout gap="8" isScrollable>
        {message && (
          <Alert
            variant="warning"
            icon={deviceType !== "mobile"}
            fullWidth
            rounded
          >
            {message}
          </Alert>
        )}
        <VerticalLayout>
          <Label
            indication="*"
          >
            {t("IBAN")}
          </Label>
          <div style={customIbanStyle}>
            <IbanElement
              options={ibanElementOptions}
            />
          </div>
        </VerticalLayout>
        <LabeledTextField
          name="email"
          indication="*"
          onChange={onInputChange}
          value={accountantInfo.email}
        >
          {t("Adresse mail")}
        </LabeledTextField>
        <LabeledTextField
          name="name"
          indication="*"
          onChange={onInputChange}
          value={accountantInfo.name}
        >
          {t("Titulaire du compte (raison sociale)")}
        </LabeledTextField>
        <VerticalLayout>
          <Label
            indication="*"
          >
            {t("Adresse de facturation")}
          </Label>
          <Select
            value={accountantInfo.address}
            onSelect={(a) => onAddressChange(a)}
            options={addresses}
          />
        </VerticalLayout>
        <VerticalLayout>
          <Label
            indication="*"
          >
            {t("Pays")}
          </Label>
          <Select
            options={countryOptions}
            onSelect={(o) => onCountryChange(o)}
          />
        </VerticalLayout>
        <Text
          size="xs"
          muted
          style={{ maxWidth: "500px" }}
          textAlign="justify"
        >
          {t("En fournissant vos informations de paiement et en confirmant ce paiement, vous autorisez (A) FLEET et Stripe, notre prestataire de services de paiement et/ou PPRO, son prestataire de services local, à envoyer des instructions à votre banque pour débiter votre compte et (B) votre banque à débiter votre compte conformément à ces instructions. Vous avez, entre autres, le droit de vous faire rembourser par votre banque selon les modalités et conditions du contrat conclu avec votre banque. La demande de remboursement doit être soumise dans un délai de 8 semaines à compter de la date à laquelle votre compte a été débité. Vos droits sont expliqués dans une déclaration disponible auprès de votre banque. Vous acceptez de recevoir des notifications des débits à venir dans les 2 jours précédant leur réalisation.")}
        </Text>
      </VerticalLayout>
    </Modal>
  );
};

export default StripeFormModal;
