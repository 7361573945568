import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Alert, Button, Icon, SelectableTile, VerticalLayout,
} from "@fleet.co/tarmac";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useDeviceType from "src/hooks/useDeviceType";
import { useUserContext } from "src/contexts/UserContext";
import StripeFormModal from "src/components/cockpit/renewal/StripeFormModal";

const SepaMandateManager = ({
  showSepaAlert, setSepaAlert, setSelectedPaymentMethodId, selectedPaymentMethodId, returnUrl, disabled,
}) => {
  const { deviceType } = useDeviceType();
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isModalOpen, setModalModalOpen] = useState(false);

  const stripePromise = useMemo(() => loadStripe(process.env.REACT_APP_STRIPE_API_KEY), []);

  const fetchPaymentMethod = useCallback(async () => {
    const { data: paymentMethodsData } = await currentUser.api.getPaymentMethods();
    const newPaymentMethodAdded = paymentMethodsData.filter((pm) => !paymentMethods.map((pm) => pm.id).includes(pm.id));

    setPaymentMethods(paymentMethodsData);

    // Autoselect newly added SEPA Mandate
    if (newPaymentMethodAdded.length === 1) {
      setSelectedPaymentMethodId(newPaymentMethodAdded[0].id);
    } else {
      const defaultPaymentMethod = paymentMethodsData.find((pm) => pm.is_default);

      setSelectedPaymentMethodId(defaultPaymentMethod?.id);
    }
  }, [currentUser.api, paymentMethods, setSelectedPaymentMethodId]);

  const handleSelectPaymentMethod = useCallback((id) => () => {
    if (disabled) return;
    setSelectedPaymentMethodId(id);
  }, [setSelectedPaymentMethodId, disabled]);

  useEffect(() => fetchPaymentMethod(), [isModalOpen]);

  return (
    <VerticalLayout gap="8">
      {paymentMethods.map((pm) => (
        <SelectableTile
          selected={selectedPaymentMethodId === pm.id}
          onClick={handleSelectPaymentMethod(pm.id)}
          style={{ width: "100%" }}
          option="radio"
          disabled={disabled}
          buttonAlignSelf="flex-start"
          label={(
            <VerticalLayout fullWidth>
              {pm.name}
              <br />
              {`**** **** **** **** **** ${pm.last4}`}
            </VerticalLayout>
          )}
        />
      ))}

      <Button variant="secondary" onClick={() => setModalModalOpen(true)}>
        <Icon icon={faPlus} size="s" />
        {t("Nouveau mandat SEPA")}
      </Button>

      {showSepaAlert && (
        <Alert
          variant="error"
          closeAlert={() => setSepaAlert(false)}
          icon={deviceType !== "mobile"}
          closeIcon
        >
          {t("Veuillez entrer un mandat SEPA")}
        </Alert>
      )}

      <Elements stripe={stripePromise}>
        {isModalOpen && <StripeFormModal setOpen={setModalModalOpen} returnUrl={returnUrl} />}
      </Elements>
    </VerticalLayout>
  );
};

export default SepaMandateManager;
