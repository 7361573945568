import React from "react";
import { HorizontalLayout, Tag } from "@fleet.co/tarmac";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";

const TagSelect = ({
  tagsList, onSelectTag, toggleMenu, setTagInfo, isEditable,
}) => (
  <HorizontalLayout gap="8" flexWrap="wrap" data-cy="Tags">
    {tagsList.map((tag, index) => (
      <Tag
        key={index}
        label={tag.label}
        color={tag.color}
        filled
        small
        handleTagClick={() => onSelectTag(tag)}
        closeIcon={isEditable}
        icon={faEllipsisV}
        handleIconClick={() => {
          setTagInfo(tag);
          toggleMenu();
        }}
      />
    ))}
  </HorizontalLayout>
);

export default TagSelect;
