import { useCallback, useState } from "react";

// Hook
const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      return item || initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        // Save state
        setStoredValue(
          typeof valueToStore === "string"
            ? valueToStore
            : JSON.stringify(valueToStore),
        );
        // Save to local storage
        window.localStorage.setItem(key, valueToStore);
        window.dispatchEvent(new Event(`${key}_storage`));
      } catch (error) {
        throw new Error(
          "Erreur avec l'enregistrement local, veuillez contacter un administrateur.",
        );
      }
    },
    [key, storedValue],
  );

  const removeValue = useCallback(() => {
    window.localStorage.removeItem(key);
    window.dispatchEvent(new Event(`${key}_storage`));
  }, [key]);

  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
