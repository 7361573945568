import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Heading, Text } from "@fleet.co/tarmac";

import styles from "../CockpitLogin.module.scss";

const LoginEmailSent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading textAlign="center" component="h1" size="L" className={styles.resetPassText}>
        <Trans>
          Un
          {" "}
          <span className="isPrimary">lien de connexion</span>
          {" "}
          vous a été envoyé
        </Trans>
      </Heading>

      <Text textAlign="center" className={styles.resetPassText}>
        {t("Veuillez vérifier votre boîte mail, nous venons de vous envoyer un lien de connexion temporaire")}
      </Text>

      <figure className="image">
        <img loading="lazy" src="/images/woman_with_computer.svg" alt="Login email sent" />
      </figure>

      <Text size="XS" muted textAlign="center">
        {t("Vous ne trouvez pas votre mail ? Vérifiez votre dossier de courrier indésirable.")}
      </Text>
    </>
  );
};

export default LoginEmailSent;
