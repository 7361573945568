import React, {
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

const Context = createContext({});

export const useConfirmModalContext = () => useContext(Context);

export const ConfirmModalProvider = ({ children }) => {
  const [confirmModal, setConfirmModal] = useState(null);

  const values = useMemo(
    () => ({
      confirmModal,
      setConfirmModal,
    }),
    [confirmModal, setConfirmModal],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
