import React, {
  useCallback, useEffect, useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Button, Checkbox, FileDropzone, Link, Modal, Text, VerticalLayout,
} from "@fleet.co/tarmac";

import { useUserContext } from "src/contexts/UserContext";
import { downloadFileBlob, fileChecking, imgExtensions } from "src/tools/FilesHelpers";

import filesNiceName from "../filesNiceName";
import InsuranceInfoForm from "../reorder/InsuranceInfoForm";

const SettingsSignatoryModal = (props) => {
  const {
    onClose, employee,
  } = props;
  const { user: currentUser } = useUserContext();
  const [signatoryInfos, setSignatoryInfos] = useState({
    number: employee.phone,
    country: employee.phone_country,
    cityOfBirth: employee.city_of_birth,
    countryOfBirth: employee.country_of_birth,
    dateOfBirth: employee.date_of_birth,
  });

  const [isLR, setIsLR] = useState(null);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState({
    SIGNATORY_ID: null,
    SIGNATORY_ID_VERSO: null,
    IBAN: null,
    BALANCE_SHEET: null,
    SIGNING_AUTHORITY: null,
  });
  const companyFilesList = ["IBAN", "BALANCE_SHEET"];
  const { t } = useTranslation();

  const downloadFile = async (file) => {
    const response = await currentUser.api.getFile(file.id);

    downloadFileBlob(response.data, (file.original_name || file.url));
  };

  const downloadModel = () => {
    let fileUrl;

    if (currentUser.company.country === "France") {
      fileUrl = "/documents/FLEET - Template - Pouvoir de signature.docx";
    } else {
      fileUrl = "/documents/FLEET - Template - Power of Attorney.docx";
    }

    window.open(fileUrl, "_self", "noopener noreferrer");
  };

  const deleteFile = async (file, fileType) => {
    await currentUser.api.deleteFile(file.id);
    setFiles((prevFiles) => ({ ...prevFiles, [fileType]: undefined }));
  };

  const submitSignatory = useCallback(async () => {
    const rolesData = isLR ? { roles_add: "LEGAL_REPRESENTATIVE" } : { roles_del: ["LEGAL_REPRESENTATIVE"] };
    const birthInfos = {
      city_of_birth: signatoryInfos.cityOfBirth,
      country_of_birth: signatoryInfos.countryOfBirth,
      date_of_birth: signatoryInfos.dateOfBirth,
    };

    const userData = {
      ...rolesData,
      ...birthInfos,
    };

    await currentUser.api.modifyUser(employee.id, userData);
    onClose();
  }, [isLR, currentUser.api, employee.id, onClose, signatoryInfos]);

  const submitFile = async (e, type) => {
    const errMsg = fileChecking(e.target.files[0], imgExtensions);

    if (errMsg) {
      setError(errMsg);

      return;
    }
    setError(null);
    const formData = new FormData();

    formData.append("type", type);

    formData.append("status", "TO_EXAMINE");
    if (!companyFilesList.includes(type)) {
      formData.append("user_id", employee.id);
    }
    formData.append("file_contents", e.target.files[0]);
    const { data: newFile } = await currentUser.api.addFile(formData);

    setFiles((prevFiles) => ({ ...prevFiles, [type]: newFile }));
  };

  useEffect(() => {
    const iban = currentUser.company.files.find((file) => file.type === "IBAN");
    const balanceSheet = currentUser.company.files.find((file) => file.type === "BALANCE_SHEET");
    const idRecto = employee?.files.find((file) => file.type === "SIGNATORY_ID");
    const idVerso = employee?.files.find((file) => file.type === "SIGNATORY_ID_VERSO");
    const signingAuthority = employee?.files.find((file) => file.type === "SIGNING_AUTHORITY");

    setFiles({
      IBAN: iban,
      BALANCE_SHEET: balanceSheet,
      SIGNATORY_ID: idRecto,
      SIGNATORY_ID_VERSO: idVerso,
      SIGNING_AUTHORITY: signingAuthority,
    });
  }, [currentUser, employee]);

  const onInputChange = (name, value) => {
    setSignatoryInfos((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  useEffect(() => {
    if (employee.roles.find((r) => r.role === "LEGAL_REPRESENTATIVE")) {
      setIsLR(true);
    }
  }, []);

  return (
    <Modal
      closeModal={onClose}
      title={t("Modifier les informations signataires")}
      primaryButton={(
        <Button fullWidth onClick={submitSignatory}>
          {t("Valider")}
        </Button>
      )}
      secondaryButton={(
        <Button fullWidth onClick={onClose}>
          {t("Fermer")}
        </Button>
      )}
    >
      <VerticalLayout gap="8" isScrollable>
        <InsuranceInfoForm signatoryInfos={signatoryInfos} onChange={onInputChange} />
        { currentUser.company?.country !== "Spain" && (
          <Checkbox
            checked={isLR}
            onClick={() => setIsLR(!isLR)}
          >
            {t("S'agit-il du représentant légal ?")}
          </Checkbox>
        )}
        {error && <Text size="s" variant="danger">{t(error)}</Text>}
        <Text muted size="s">{t("Entreprise")}</Text>

        {Object.keys(files).map((fileName, i) => (
          !companyFilesList.includes(fileName) ? null
            : (
              <FileDropzone
                key={i}
                selectFile={(e) => submitFile(e, fileName)}
                isSmall
                width="100%"
                files={files[fileName]}
                acceptedExtensions={imgExtensions.join(", ")}
                onDownload={downloadFile}
                onDelete={(file) => deleteFile(file, fileName)}
                text={t(filesNiceName[fileName])}
                label={t(filesNiceName[fileName])}
              />
            )
        ))}
        <Text muted size="s">{t("Pièces d'identité du signataire")}</Text>
        {Object.keys(files).map((fileName, i) => (
          (fileName === "SIGNING_AUTHORITY" && isLR) || companyFilesList.includes(fileName) ? null
            : (
              <FileDropzone
                key={i}
                selectFile={(e) => submitFile(e, fileName)}
                isSmall
                width="100%"
                files={files[fileName]}
                acceptedExtensions={imgExtensions.join(", ")}
                onDownload={downloadFile}
                onDelete={(file) => deleteFile(file, fileName)}
                text={t(filesNiceName[fileName])}
                label={t(filesNiceName[fileName])}
              />
            )
        ))}
        {!isLR && (
          <Text muted size="xs">
            <Trans>
              Un pouvoir de signature est nécessaire pour déléguer la signature
              du contrat de location.
              {" "}
              <Link onClick={downloadModel} span="true">Téléchargez ici</Link>
              {" "}
              le modèle et ajoutez-le une fois signé.
            </Trans>
          </Text>
        )}
      </VerticalLayout>
    </Modal>
  );
};

export default SettingsSignatoryModal;
