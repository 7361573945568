import React, { useEffect } from "react";
import { Toast } from "@fleet.co/tarmac";

const ToastContainer = (props) => {
  const { toast, closeToast } = props;

  useEffect(() => {
    const toastTimer = setTimeout((t) => closeToast(t.uid), 3000, toast);

    return () => clearTimeout(toastTimer);
  }, [toast, closeToast]);

  return (
    <Toast
      key={toast.uid}
      data-cy="toast"
      icon
      closeIcon
      {...toast}
      closeToast={() => closeToast(toast.uid)}
    />
  );
};

export default ToastContainer;
