const fileChecking = (files, authorizedTypes) => {
  const filesToDisplay = Array.isArray(files)
    ? files
    : [files];

  for (const file of filesToDisplay) {
    if (file.size > 5242880) {
      return "La taille du fichier ne doit pas exceder 5MB.";
    }

    if (authorizedTypes && !authorizedTypes.includes(file.type)) {
      return authorizedTypes.includes("text/csv")
        ? "Les extensions autorisées sont .csv, .xls, .xlsx"
        : "Les extensions autorisées sont .jpg, .jpeg, .png, .pdf";
    }
  }

  return null;
};

const imgExtensions = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
const xlsExtensions = [
  "text/csv",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const downloadFileBlob = (blob, fileName) => {
  const checkFileName = fileName || "untitled.pdf";
  const responseTypeBlob = blob instanceof Blob;

  if (responseTypeBlob) {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", checkFileName);
    document.body.appendChild(link);
    link.click();
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);

    return URL.revokeObjectURL(url);
  }
  console.error("The api response doesn't return a Blob type", blob);

  return null;
};

export {
  fileChecking, imgExtensions, xlsExtensions, downloadFileBlob,
};
