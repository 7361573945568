import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { faUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import {
  Button, Icon, LegacyCollapsableBox, Modal, VerticalLayout,
} from "@fleet.co/tarmac";

import ItemsNumberedList from "src/components/cockpit/contract/blocks/ItemsNumberedList";
import { useUserContext } from "src/contexts/UserContext";

import styles from "./LeaserConnexionModal.module.scss";

const LeaserConnexionModal = (props) => {
  // PROPS & CONTEXT
  const { onClose, leaserInfo } = props;
  const { t } = useTranslation();
  const leaserName = leaserInfo.name;

  return (
    <Modal
      title={t("Première connexion à la plateforme {{leaserName}}", { leaserName })}
      closeModal={onClose}
      secondaryButton={(
        <Button onClick={onClose}>
          {t("Fermer")}
        </Button>
      )}
    >
      <VerticalLayout isScrollable>
        <Button variant="secondary" onClick={() => window.open(leaserInfo.platformLink)} className={styles.linkButton}>
          {t("Accéder à la plateforme")}
          <Icon icon={faUpRightFromSquare} size="s" />
        </Button>
        {leaserInfo.value === "BNP" && <BnpConnexion leaserInfos={leaserInfo} />}
        {leaserInfo.value === "Grenke" && <GrenkeConnexion leaserInfos={leaserInfo} />}
      </VerticalLayout>
    </Modal>
  );
};

export default LeaserConnexionModal;

const BnpConnexion = (props) => {
  const { leaserInfos } = props;
  const { t } = useTranslation();
  const { user } = useUserContext();

  return (
    <div style={{ flex: 1, marginTop: "2em" }}>
      <ItemsNumberedList>
        <div>
          {t("Rendez-vous dans votre espace client BNP : ")}
          <a href={leaserInfos.platformLink} target="blank">
            {leaserInfos.platformLink}
          </a>
          .
        </div>
        <div>
          {t(`Sur la page de connexion, cliquez sur "S'inscrire". Puis sur l'écran "Description de la procédure
            d'inscription", cliquez de nouveau sur "S'inscrire".`)}
        </div>
        {leaserInfos.contractNumber ? (
          <div>
            {t("Dans le champ \"Numéro de contrat\", saisissez le numéro de contrat suivant :")}
            <br />
            <div className={styles.clickableBox}>
              <p className={styles.clickableBoxContent}>{leaserInfos.contractNumber}</p>
            </div>
          </div>
        ) : (
          <div>{t("Dans le champ \"Numéro de contrat\", saisissez l'un de vos numéros de contrat BNP")}</div>
        )}
        {!user.company.bnp_number ? (
          <div>
            {t("Dans le champ \"Numéro de client\", saisissez le numéro de client qui vous a été envoyé par email.")}
            <br />
            {t("Si vous ne le retrouvez pas, vous pouvez le redemander à l'adresse :")}
            <div className={styles.clickableBox}>
              <div className={styles.clickableBoxContent}>support@fleet.co</div>
            </div>
          </div>
        ) : (
          <div>
            {t("Dans le champ \"Numéro de client\", saisissez le numéro de client suivant :")}
            <div className={styles.clickableBox}>
              <div className={styles.clickableBoxContent}>{user.company.bnp_number}</div>
            </div>
          </div>
        )}
        <div>{t("Validez le captcha puis cliquez sur \"Rechercher\".")}</div>
        <div>
          {t(`Vous devriez ensuite recevoir un mail de confirmation accompagné de votre identifiant et d"un mot de passe
            provisoire. Pensez à vérifier vos spams !`)}
        </div>
        <div>
          <Trans>
            Retournez sur la
            {" "}
            <a href={leaserInfos.platformLink} target="blank">
              plateforme BNP
            </a>
            {" "}
            pour vous connecter avec les identifiants reçus.
          </Trans>
        </div>
        <div>
          {t("Cliquez ensuite sur \"Accéder à mon compte\". Une fois connecté, vous pourrez changer votre mot de passe.")}
          <br />
          {t("Pour vos connexions futures, vous aurez besoin de votre identifiant et de ce nouveau mot de passe.")}
        </div>
      </ItemsNumberedList>
      <br />
      <div>
        {t(`Lors de votre inscription, il est possible que vous rencontriez des problèmes pour vous identifier via votre
          numéro de contrat. Cela est dû aux délais de facturation. Dans ce cas, nous vous invitons à attendre quelques
          jours avant de réitérer la procédure d'inscription.`)}
      </div>
    </div>
  );
};

const GrenkeConnexion = (props) => {
  const { leaserInfos } = props;
  const { t } = useTranslation();
  const { platformLink } = leaserInfos;

  return (
    <div style={{ flex: 1 }}>
      <LegacyCollapsableBox title={t("Première connexion")}>
        <ItemsNumberedList>
          <div>
            {t("Rendez-vous dans votre espace client Grenke : ")}
            <a href={leaserInfos.platformLink} target="blank">
              {leaserInfos.platformLink}
            </a>
          </div>
          <div>{t("Cliquez sur \"Inscription\".")}</div>
          <div>{t("Saisissez votre adresse mail et un mot de passe.")}</div>
          <div>
            {t(`Pour le champ "Code d’activation", vous avez dû recevoir un code d'accès par courrier à l'adresse de votre
            siège social.`)}
            <br />
            {t(`Si ce n"est pas le cas ou si vous ne l'avez plus, vous pouvez le redemander en cliquant sur "Vous n'avez
            pas encore votre code de validation ?"`)}
          </div>
          {leaserInfos.contractNumber ? (
            <div>
              {t("Saisissez le numéro de contrat ci-dessous dans le champ \"Numéro de contrat\".")}
              <br />
              <div className={styles.clickableBox}>
                <p className={styles.clickableBoxContent}>{leaserInfos.contractNumber}</p>
              </div>
            </div>
          ) : (
            <div>
              {t("Saisissez l'un de vos numéros de contrat {{leaserName}} dans le champ \"Numéro de contrat\".", {
                leaserName: leaserInfos.name,
              })}
            </div>
          )}
        </ItemsNumberedList>
      </LegacyCollapsableBox>
      <LegacyCollapsableBox title={t("Demander un code d'activation")}>
        {t("Vous avez dû recevoir un code d'accès par courrier à l'adresse de votre siège social.")}
        <br />
        <ItemsNumberedList>
          <Trans>
            <div>
              Si ce n'est pas le cas ou si vous ne l’avez plus, rendez-vous sur
              {" "}
              <a href={leaserInfos.platformLink} target="blank">
                {{ platformLink }}
              </a>
              {" "}
              puis cliquez sur "Vous n'avez pas encore votre code de validation ?"
            </div>
          </Trans>
          {leaserInfos.contractNumber ? (
            <div>
              {t("Saisissez le numéro de contrat ci-dessous dans le champ \"Numéro de contrat\".")}
              <br />
              <div className={styles.clickableBox}>
                <p className={styles.clickableBoxContent}>{leaserInfos.contractNumber}</p>
              </div>
            </div>
          ) : (
            <div>
              {t("Saisissez l'un de vos numéros de contrat {{leaserName}} dans le champ \"Numéro de contrat\".", {
                leaserName: leaserInfos.name,
              })}
            </div>
          )}
          <div>{t("Un nouveau code d’activation sera alors envoyé au siège de votre entreprise par courrier.")}</div>
        </ItemsNumberedList>
      </LegacyCollapsableBox>
    </div>
  );
};
