import React from "react";
import { useTranslation } from "react-i18next";
import { Button, HorizontalLayout } from "@fleet.co/tarmac";

const GoogleConnect = (props) => {
  const { onConnect, disabled } = props;
  const { t } = useTranslation();

  return (
    <Button variant="secondary" onClick={onConnect} fullWidth disabled={disabled}>
      <HorizontalLayout gap={16} alignItems="center">
        <img src="/images/Google.svg" alt="google icon" width={24} height={24} />
        {t("Continuer avec Google")}
      </HorizontalLayout>
    </Button>
  );
};

export default GoogleConnect;
