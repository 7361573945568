import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Modal } from "@fleet.co/tarmac";

import ItemsNumberedList from "src/components/cockpit/contract/blocks/ItemsNumberedList";

import styles from "./LeaserRibModal.module.scss";

const LeaserRibModal = (props) => {
  // PROPS & CONTEXT
  const { leaserInfo, onClose } = props;
  const { t } = useTranslation();
  const leaserName = leaserInfo.value;

  return (
    <Modal
      title={t("Changer mon RIB {{leaserName}}", { leaserName })}
      closeModal={onClose}
      secondaryButton={(
        <Button onClick={onClose}>
          {t("Fermer")}
        </Button>
      )}
    >
      <div className={styles.ribContent}>
        {leaserInfo.value !== "Locam" ? (
          <ItemsNumberedList>
            <div>
              <Trans>
                Télécharger et remplir le
                {" "}
                <a href={leaserInfo.sepaLink} target="blank">
                  nouveau mandat de prélèvement SEPA
                  {" "}
                  {{ leaserName }}
                </a>
                .
              </Trans>
            </div>
            <div>{t("Obtenir le RIB du nouveau compte à prélever.")}</div>
            <div>
              <Trans>
                Envoyer ensuite ces deux documents à
                {" "}
                <div className={styles.clickableBox}>
                  <p className={styles.clickableBoxContent}>support@fleet.co</p>
                </div>
              </Trans>
            </div>
          </ItemsNumberedList>
        ) : (
          <ItemsNumberedList>
            <div>{t("Obtenir le RIB du nouveau compte à prélever.")}</div>
            <div>
              {t("Envoyer ensuite ce document à ")}
              <div className={styles.clickableBox}>
                <p className={styles.clickableBoxContent}>locam.client@locam.fr</p>
              </div>
            </div>
          </ItemsNumberedList>
        )}
      </div>
    </Modal>
  );
};

export default LeaserRibModal;
