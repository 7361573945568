import React from "react";
import { useTranslation } from "react-i18next";
import {
  HorizontalLayout, Link, Text, VerticalLayout,
} from "@fleet.co/tarmac";

import styles from "../OrderSignatoryModal.module.scss";

const SignatoriesOptions = (props) => {
  const {
    goAddView, getSignatoryInfo, signatories, onClick, isOrderMonitoring,
  } = props;
  const { t } = useTranslation();

  const handleClick = (signatoryId) => {
    getSignatoryInfo(signatoryId);
    onClick();
  };

  return (
    <VerticalLayout gap="8" isScrollable fullHeight>
      {signatories.map((signatory, i) => (

        <VerticalLayout
          gap="8"
          key={i}
          onClick={() => handleClick(signatory.id)}
          className={styles.dropdownOption}
        >
          <Text>
            {signatory.firstName}
            {" "}
            {signatory.lastName}
          </Text>
        </VerticalLayout>

      ))}
      {!isOrderMonitoring && (
      <HorizontalLayout justifyContent="space-between" className={styles.missingOption}>
        <Text size="S" bold>{t("Pas dans la liste ?")}</Text>
        <Link onClick={goAddView}>{t("Ajouter un signataire")}</Link>
      </HorizontalLayout>
      )}

    </VerticalLayout>
  );
};

export default SignatoriesOptions;
