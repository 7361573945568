import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Text, VerticalLayout } from "@fleet.co/tarmac";

const CatalogSelectFilter = (props) => {
  const {
    filter,
    category,
    filters,
    onChangeFilters,
    resetFilter,
  } = props;
  const { t } = useTranslation();

  const isOptionAnySelected = useCallback((filterKey) => {
    if (filters[filterKey] === null) {
      return true;
    }

    if (Array.isArray(filters[filterKey]) && filters[filterKey].length === 0) {
      return true;
    }

    return false;
  }, [filters]);

  const isOptionSelected = useCallback((filterKey, optionValue) => {
    if (filters[filterKey] === optionValue) {
      return true;
    }

    if (Array.isArray(filters[filterKey]) && filters[filterKey].includes(optionValue)) {
      return true;
    }

    return false;
  }, [filters]);

  return (
    <VerticalLayout gap={8} data-cy="filterOptions">
      <Checkbox
        type="primary"
        size="S"
        checked={isOptionAnySelected(filter.key)}
        disabled={isOptionAnySelected(filter.key)}
        onClick={() => resetFilter(filter.key)}
      >
        <Text size="XS">{t("Peu importe")}</Text>
      </Checkbox>

      {filter.options(category.name).map((option) => (
        <Checkbox
          type="primary"
          size="S"
          checked={isOptionSelected(filter.key, option.value)}
          onClick={() => onChangeFilters(filter.key, option.value)}
        >
          <Text size="XS">{option.translate ? t(option.label) : option.label}</Text>
        </Checkbox>
      ))}
    </VerticalLayout>
  );
};

export default CatalogSelectFilter;
