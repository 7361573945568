import React, { useEffect, useState } from "react";
import RPI from "react-phone-input-2";
import { useUserContext } from "src/contexts/UserContext";
import "react-phone-input-2/lib/high-res.css";

const inputStyle = {
  borderRadius: "8px",
  width: "100%",
  height: "40px",
  border: "solid 1px #ededed",
  color: "#222222",
  fontFamily: "\"Circular Std\", arial, sans-serif",
};

const buttonStyle = {
  backgroundColor: "#F9F9FB",
  border: "solid 1px #ededed",
  borderRight: "none",
};

const dropdownStyle = {
  borderRadius: "8px",
  boxShadow: "none",
  border: "solid 1px #ededed",
};

const countryToPhoneCountryMap = {
  France: "fr",
  Germany: "de",
  Spain: "es",
  "United Kingdom": "gb",
  Other: "fr",
};

const PhoneInput = ({
  country, onChange, setIsPhoneValid, value, ...otherProps
}) => {
  const [isValid, setIsValid] = useState(null);
  const { user: currentUser } = useUserContext();

  const handleChange = (value, country, _, formattedValue) => {
    // Consider phone valid if at least 7 digits
    // See : https://codesandbox.io/s/react-bf3uh?file=/src/Validation.js
    // And : https://stackoverflow.com/a/1245990/750758
    // Do not over-engineer as it is too complex, brings false-positives and is easily bypassed
    setIsValid(formattedValue?.length > 6);
    onChange(value, country.countryCode);
    onChange(`+${value}`, country.countryCode);
  };

  useEffect(() => {
    if (setIsPhoneValid && isValid !== null) {
      setIsPhoneValid(isValid);
    }
  }, [isValid, value]);

  return (
    <RPI
      country={country || countryToPhoneCountryMap[currentUser?.company?.country] || "fr"}
      preferredCountries={["fr", "es", "de", "gb"]}
      inputStyle={inputStyle}
      buttonStyle={buttonStyle}
      dropdownStyle={dropdownStyle}
      onChange={handleChange}
      inputProps={{ ...otherProps }}
      value={value || ""}
      enableLongNumbers
    />
  );
};

export default PhoneInput;
