// User
import ApiService from "src/tools/ApiService";
import GlobalApiService from "src/tools/GlobalApiService";
import { deviceSortFn } from "src/tools/DeviceGrouping";

class User {
  visibleOrderStatus = [
    "LEAD_AGREEMENT",
    "LEASER_AGREEMENT",
    "LEASER_REQUESTED",
    "LEASER_MORE_DOCUMENTS",
    "LEASER_DISAGREEMENT",
    "CHECK_DOC",
    "LEAD_SIGNING",
    "READY_FOR_PURCHASE",
    "PURCHASED",
    "SHIPPED",
    "DELIVERED",
    "BDL_SENT",
    "RECEIVED",
    "FINALIZED",
    "CLOSED",
    "INACTIVE",
  ];

  validatedOrderStatus = [
    "READY_FOR_PURCHASE",
    "PURCHASED",
    "SHIPPED",
    "DELIVERED",
    "BDL_SENT",
    "RECEIVED",
    "FINALIZED",
    "CLOSED",
    "INACTIVE",
  ];

  load = () => {
    this.api = new ApiService(this);
    this.globalApi = new GlobalApiService();
    this.company.devices = this.sortDevices();

    return this;
  };

  isLoaded = (onboarding = false) => {
    if (typeof this.api === "undefined") {
      return false;
    }

    if (typeof this.company === "undefined") {
      return false;
    }

    if (typeof this.company.orders === "undefined") {
      return false;
    }

    if (typeof this.company.devices === "undefined" && !onboarding) {
      return false;
    }

    if (typeof this.company.tickets === "undefined" && !onboarding) {
      return false;
    }

    if (this.deleted) {
      return false;
    }

    return true;
  };

  isAnonymous = () => !this.email;

  getOnboardingStatus = () => {
    switch (this.info_level) {
      case 0:
        return "NOT_STARTED";
      case 1:
        return "STARTED";
      case 2:
        return "IN_PROGRESS";
      case 3:
        return "COMPLETE";
      default:
        return "";
    }
  };

  // Add helpers here
  hasRole = (role) => this.roles && this.roles.filter((r) => r.role === role).length;

  isAdmin = () => this.hasRole("ADMIN") > 0;

  checkIfCompanyIntegrationisActive = (integrationType) => this.company.integrations?.some((itg) => itg.type === integrationType && itg.status === "ACTIVE");

  isSyncedWith = (integration) => this.company.integrations?.some((itg) => itg.provider === integration && itg.status === "ACTIVE" && this.sirh_sync_activated);

  isCompanyOnboarded = () => {
    const members = this.company.members || this.company.employees || [];

    // At least one user has finished onboarding in the company
    const onboardedUser = members.find((m) => m.roles.find((r) => r.role === "ADMIN") && m.info_level === 3);
    const hasEmployeeAccess = (this.hasRole("EMPLOYEE") > 0) && this.checkIfCompanyIntegrationisActive("EMPLOYEE_ACCESS");

    return onboardedUser !== undefined || hasEmployeeAccess;
  };

  canAccessCockpit = () => {
    const isOnboardedAdmin = this.isAdmin();
    const hasEmployeeAccess = (this.hasRole("EMPLOYEE") > 0) && this.checkIfCompanyIntegrationisActive("EMPLOYEE_ACCESS");

    return isOnboardedAdmin || hasEmployeeAccess;
  };

  companyInfoCompleted = () => {
    if ((this.company?.country === "France" && !this.company?.siren)
      || (this.company?.country === "Germany" && !this.company?.hr)
      || (this.company?.country === "Spain" && !this.company?.nif)) {
      return false;
    }

    if (!this.company?.legal_name) {
      return false;
    }

    return true;
  };

  isFirstOrder = () => {
    if (this.company.orders && this.company.orders.length > 0) {
      return !this.company.orders.some((o) => this.visibleOrderStatus.includes(o.status));
    }

    return true;
  };

  sortDevices = () => this.company.devices.sort(deviceSortFn);

  getVisibleDevices = () => {
    if (!this.company.devices || this.company.devices.length === 0) {
      return [];
    }

    return this.company.devices
      .filter((d) => {
        if (d.source === "EXTERNAL") {
          return true;
        }
        const deviceOrder = this.company.orders.find((o) => o.id === d.order_id);

        return deviceOrder && this.visibleOrderStatus.includes(deviceOrder.status) && d.renewal_type !== "UPGRADE";
      })
      .sort(deviceSortFn);
  };

  getDevicesForOrder = (orderId) => this.company.devices.filter((d) => d.order_id === orderId);

  getVisibleOrders = () => {
    if (!this.company.orders || this.company.orders.length === 0) {
      return [];
    }

    return this.company.orders.filter((o) => this.visibleOrderStatus.includes(o.status));
  };

  getValidatedOrders = () => {
    if (!this.company.orders || this.company.orders.length === 0) {
      return [];
    }

    return this.company.orders.filter((o) => this.validatedOrderStatus.includes(o.status));
  };

  getLastFinishedOrder = async () => {
    if (!this.company.orders || this.company.orders.length === 0) {
      return null;
    }
    // get the last order in the "LEAD_AGREEMENT" status
    const leadAgreements = this.company.orders.filter((o) => o.status === "LEAD_AGREEMENT");

    if (!leadAgreements.length) return null;

    const lastOrder = leadAgreements
      .sort((a, b) => new Date(b.order_date) - new Date(a.order_date))[0];

    const fetchedOrder = await this.api.getOrder(lastOrder.id);

    return fetchedOrder.data;
  };

  reloadOrders = async () => {
    const fetchedList = await this.api.getOrders();
    const sortedOrders = await fetchedList.data.sort((a, b) => {
      const c = new Date(a.order_date);
      const d = new Date(b.order_date);

      return d - c;
    });

    this.company.orders = sortedOrders;

    return this;
  };

  reloadEmployees = async () => {
    const fetchedList = await this.api.getUsers();

    this.company.employees = fetchedList.data;

    return this;
  };

  getYousignSignatureLink = async (o) => {
    let order = o;

    if (!order.yousign_procedures) {
      // This endpoint adds missing joins to order object
      // Including order.yousign_procedures
      const orderFetched = await this.api.getOrder(o.id);

      order = orderFetched.data;
    }

    const yousignBaseUrl = process.env.REACT_APP_YOUSIGN_URL;
    let yousignClientId = null;

    const yousignProcedures = order.yousign_procedures;

    if (!yousignProcedures || yousignProcedures.length === 0) {
      return "";
    }

    const lastValidProcedure = yousignProcedures
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .find((p) => p.status === "ACTIVE");

    if (!lastValidProcedure) {
      return "";
    }

    if (order.status === "LEAD_SIGNING") {
      yousignClientId = lastValidProcedure.client_first_id;
    }

    if (order.status === "BDL_SENT") {
      yousignClientId = lastValidProcedure.client_second_id;
    }

    if (!yousignClientId) {
      return "";
    }

    return `${yousignBaseUrl}/procedure/sign?members=${yousignClientId}&signatureUi=/${process.env.REACT_APP_YOUSIGN_SIGNATURE_UI}`;
  };

  hasFinishedDevicesSetup = () => this.getVisibleDevices().length > 1;

  hasFinishedEmployeesSetup = () => this.company.employees.length > 2;

  hasFinishedAdminSetup = () => this.company.employees.filter((u) => u.roles.find((r) => r.role === "ADMIN") && u.id !== this.id).length > 0;

  getCompanyDiscount = (price) => {
    const productCompanyDiscount = this.company.product_discounts.find((discount) => {
      const isAboveMinPrice = Number(discount.min_price) <= price;
      const isBelowMaxPrice = !discount.max_price || Number(discount.max_price) >= price;

      return isAboveMinPrice && isBelowMaxPrice;
    });

    return productCompanyDiscount;
  };

  getCompanyActiveOffer = () => this.company?.offers.find((offer) => offer.status === "ACTIVE");

  getCompanyUsableReferralCredit = () => this.company?.company_credits.find((credit) => credit.used === false);

  getCompanyCurrency = () => {
    if (this.company?.country === "United Kingdom") {
      return "GBP";
    }

    return "EUR";
  };

  getDiscountedProductPrice = (product) => {
    const productPrice = product.prices.find((p) => p.currency === this.getCompanyCurrency()).amount;
    const discount = this.getCompanyDiscount(productPrice);
    const discountValue = discount?.offer_type === "MONTH_OFFERED" ? 0 : (Number(discount?.offer_value) || 0);

    return productPrice - discountValue;
  };

  getMdmElligibleDevices = () => this.getVisibleDevices().filter((d) => d.category === "COMPUTER" && d.source !== "EX-FLEET");
}

export default User;
