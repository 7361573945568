import React, {
  useCallback, useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  Alert, Button, FileDropzone, Heading, HorizontalLayout, Modal, Text, VerticalLayout,
} from "@fleet.co/tarmac";

import { useUserContext } from "src/contexts/UserContext";

import { downloadFileBlob, fileChecking, imgExtensions } from "src/tools/FilesHelpers";

const UploadDocumentsModal = (props) => {
  const {
    onClose, fileName, order, onSave,
  } = props;
  const { t } = useTranslation();
  const { user: currentUser, reloadUser } = useUserContext();
  const [error, setError] = useState("");
  const [files, setFiles] = useState({});

  // when file is refused, display reason
  const displayRefusalReason = useCallback((file) => {
    const refusalComment = {
      EXPIRED: "Ce document n’est plus valide",
      UNOFFICIAL: "Ce document n’est pas officiel",
      QONTO_RIB: "Qonto n’est pas accepté par le partenaire financier, veuillez ajouter le RIB d’une autre banque",
      OTHER: file?.refusal_reason_other,
    };

    return refusalComment[file?.refusal_reason];
  }, []);

  useEffect(() => {
    if (fileName) {
      const file = currentUser.company.files.find((file) => file.type === fileName);

      setFiles({ [fileName]: file });
      setError(displayRefusalReason(file));
    }
  }, [currentUser, fileName, displayRefusalReason]);

  const downloadFile = useCallback(async (file) => {
    const response = await currentUser.api.getFile(file.id);

    downloadFileBlob(response.data, file.original_name);
  }, [currentUser.api]);

  const deleteFile = useCallback(async (file) => {
    await currentUser.api.deleteFile(file.id);
    await reloadUser();
  }, [currentUser.api, reloadUser]);

  const submitFile = useCallback(async (e, type) => {
    const errMsg = fileChecking(e.target.files[0], imgExtensions);

    if (errMsg) {
      setError(errMsg);

      return;
    }
    setError(null);

    const formData = new FormData();

    formData.append("type", type);
    formData.append("status", "TO_EXAMINE");
    formData.append("file_contents", e.target.files[0]);

    if (order) {
      formData.append("order_id", order.id);
    }

    await currentUser.api.addFile(formData);
    await reloadUser();
  }, [currentUser.api, reloadUser, order]);

  const handleSave = () => {
    if (typeof onSave === "function") {
      onSave();
    }

    onClose();
  };

  return (
    <Modal
      title={t("Ajouter des documents")}
      closeModal={onClose}
      primaryButton={(
        <Button fullWidth onClick={handleSave}>
          {t("Valider")}
        </Button>
      )}
    >
      <VerticalLayout gap={16}>
        {fileName === "INSURANCE" && (
          <>
            <Heading size="S">{t("Attestation d’assurance bureau")}</Heading>
            <Text>{t("Merci de fournir une attestation de votre bureau")}</Text>
          </>
        )}

        {fileName === "BALANCE_SHEET" && (
          <>
            <Heading size="S">{t("Bilan financier")}</Heading>
            <Text>{t("Merci de fournir un bilan comptable ou tout autre document pouvant appuyer votre solvabilité :")}</Text>

            <VerticalLayout>
              <HorizontalLayout gap={16}>
                <Text>•</Text>
                <Text>{t("Trésorerie disponible sur le compte")}</Text>
              </HorizontalLayout>

              <HorizontalLayout gap={16}>
                <Text>•</Text>
                <Text>{t("Levée de fonds")}</Text>
              </HorizontalLayout>

              <HorizontalLayout gap={16}>
                <Text>•</Text>
                <Text>{t("Prêt bancaire")}</Text>
              </HorizontalLayout>

              <HorizontalLayout gap={16}>
                <Text>•</Text>
                <Text>{t("Investisseurs")}</Text>
              </HorizontalLayout>

              <HorizontalLayout gap={16}>
                <Text>•</Text>
                <Text>{t("Bilan provisionnel de trésorerie")}</Text>
              </HorizontalLayout>

              <HorizontalLayout gap={16}>
                <Text>•</Text>
                <Text>{t("Autres documents financiers")}</Text>
              </HorizontalLayout>
            </VerticalLayout>
          </>
        )}

        {error && <Alert variant="error">{t(error)}</Alert>}

        <FileDropzone
          selectFile={(e) => submitFile(e, fileName)}
          isSmall
          width="100%"
          files={files[fileName]}
          acceptedExtensions={imgExtensions.join(", ")}
          onDownload={downloadFile}
          onDelete={deleteFile}
          text={t("Cliquez ici ou déposez le fichier à charger")}
        />
      </VerticalLayout>
    </Modal>
  );
};

export default UploadDocumentsModal;
