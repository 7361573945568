import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "src/scss/index.scss";

import { IntercomProvider } from "react-use-intercom";

import ScrollToTop from "src/tools/ScrollToTop";
import LoadingSpinner from "src/components/LoadingSpinner";
import { UserProvider } from "src/contexts/UserContext";
import { ToastProvider } from "src/contexts/ToastContext";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <Suspense fallback={<LoadingSpinner />}>
      <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID}
        autoBoot={false}
        shouldInitialize={false}
      >
        <ScrollToTop>

          <UserProvider>
            <ToastProvider>
              <App />
            </ToastProvider>
          </UserProvider>
        </ScrollToTop>
      </IntercomProvider>
    </Suspense>
  </Router>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
