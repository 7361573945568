import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";

const Context = createContext({});

export const useCurrentOrderContext = () => useContext(Context);

export const CurrentOrderProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addToast } = useToastContext();
  const [order, setOrder] = useState(null);
  const isAdmin = Boolean(user?.roles?.find((r) => r.role === "ADMIN"));

  const reloadOrder = useCallback(async () => {
    try {
      const fetchedOrder = await user?.api.getOngoingOrder();

      setOrder(fetchedOrder.data);
    } catch (err) {
      console.error(err);
      addToast(t("Une erreur est survenue lors de la récupération de la commande"));
    }
  }, [user?.api, t, addToast]);

  const updateOrder = useCallback(async (orderData) => {
    try {
      const updatedOrder = await user?.api.modifyOrder(order?.id, orderData);

      setOrder(updatedOrder.data);
    } catch (err) {
      console.error(err);
      addToast(t("Une erreur est survenue lors de la mise à jour de la commande"));
    }
  }, [user?.api, t, addToast, order?.id]);

  useEffect(() => {
    if (user) {
      if (!isAdmin) return;
      reloadOrder();
    }
  }, [user, isAdmin]);

  const values = useMemo(
    () => ({
      order,
      reloadOrder,
      updateOrder,
    }),
    [order, reloadOrder, updateOrder],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
