import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  ClickableCard,
  Heading,
  HorizontalLayout,
  Icon,
  Link,
  Tag, Text, VerticalLayout,
} from "@fleet.co/tarmac";
import {
  faBarcode, faLaptop, faUser, faUserSlash,
} from "@fortawesome/pro-regular-svg-icons";

import DeviceStatus from "src/components/cockpit/device/blocks/DeviceStatus";

import { whichIconTypeToShow } from "src/tools/IconHelpers";
import styles from "./ListCard.module.scss";

const DeviceClickableCard = (props) => {
  const {
    device,
    buttonType,
    buttonLabel,
    onButtonClick,
    displayAssignee,
    userToShow,
    emptyText,
    emptyType,
    icon,
    ...rest
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  let url = null;

  if (device?.product && device?.product?.product_group) {
    url = device.product.product_group.img_url;
  }

  const activeParcel = device?.activeReturn;
  const returnDate = activeParcel ? new Date(activeParcel.pickup_date).toLocaleDateString("fr") : null;
  const today = new Date().toLocaleDateString("fr");

  const returnTagStep = () => {
    const returnType = ["STOP", "RENEW", "BROKEN"];

    if (returnType.includes(device.renewal_type)) {
      if (activeParcel?.label_url) {
        if (returnDate === today) {
          return <Tag filled color="green" small label={t("Enlèvement aujourd’hui")} />;
        }

        if (new Date(activeParcel.pickup_date) < new Date()) {
          return <Tag filled color="green" small label={t("Envoyé")} />;
        }

        return <Tag filled color="beige" small label={t("Enlèvement le {{pickupDate}}", { pickupDate: returnDate })} />;
      }

      return <Tag filled color="beige" small label={t("À envoyer")} />;
    }

    if (device.renewal_type === "LOST") {
      return <Tag filled color="red" small label={t("Perdu")} />;
    }

    if (device.renewal_type === "BUYBACK") {
      return <Tag filled color="green" small label={t("Rachat")} />;
    }

    return null;
  };

  const getLink = () => {
    if (emptyType === "device") history.push("/catalog/category/hardware/device");
    else if (emptyType === "accessory") history.push("/catalog/category/hardware/accessory");
  };

  const avatar = device ? whichIconTypeToShow(device) : faLaptop;

  return (
    <ClickableCard
      buttonType={buttonType}
      buttonLabel={buttonLabel}
      onButtonClick={onButtonClick}
      {...rest}
    >
      <HorizontalLayout
        gap={device ? "8" : "16"}
        {...(!device && { alignItems: "center" })}
        className={styles.cardContent}
        fullWidth
      >
        <Avatar
          url={url}
          variant="device"
          icon={avatar}
          className={styles.avatar}
        />
        {device ? (
          <VerticalLayout gap="8" className={styles.textContainer}>
            <Heading className={styles.overflowHeading} size="XS">
              {device.name}
            </Heading>
            <HorizontalLayout gap="16" alignItems="center" flexWrap="wrap">
              <DeviceStatus device={device} />
              {!displayAssignee && device.serial_number && (
                <HorizontalLayout gap="8">
                  <Icon size="S" icon={faBarcode} />
                  {device.serial_number}
                </HorizontalLayout>
              )}
              {displayAssignee && (
                <HorizontalLayout gap="8">
                  <Icon size="S" icon={userToShow ? faUser : faUserSlash} />
                  {userToShow?.trim() || t("Non attribué")}
                </HorizontalLayout>
              )}
              {(activeParcel?.id || device.renewal_type) && returnTagStep()}
            </HorizontalLayout>
          </VerticalLayout>
        ) : (
          <HorizontalLayout fullWidth justifyContent="space-between">
            <Text muted>{emptyText}</Text>
            <Link variant="action" onClick={getLink}>
              {t("Nouvelle commande")}
            </Link>
          </HorizontalLayout>
        )}
      </HorizontalLayout>
    </ClickableCard>
  );
};

export default DeviceClickableCard;
