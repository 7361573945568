import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Text, VerticalLayout } from "@fleet.co/tarmac";

import { useUserContext } from "src/contexts/UserContext";
import { productFiltersList } from "src/tools/ProductHelpers";

import CatalogSelectFilter from "./CatalogSelectFilter";
import CatalogRangeFilter from "./CatalogRangeFilter";

const CatalogFilters = (props) => {
  const {
    category, filters, onChangeFilters, resetFilter,
  } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();

  useEffect(() => {
    // remove delivery filter if company is not french
    const deliveryFilterIndex = productFiltersList.findIndex((filter) => filter.key === "deliveryMaxTime");

    if (currentUser.company?.country !== "France") {
      productFiltersList.splice(deliveryFilterIndex, deliveryFilterIndex);
    }
  }, []);

  return (
    <VerticalLayout gap={32} flex="0 0 216px" data-cy="catalogFiltersContainer">
      {productFiltersList
        .filter((f) => f.categories.includes(category.name))
        .map((filter) => (
          <VerticalLayout gap={16}>
            <Text size="S">{t(filter.title)}</Text>

            {filter.type === "select" && (
              <CatalogSelectFilter
                filter={filter}
                category={category}
                filters={filters}
                onChangeFilters={onChangeFilters}
                resetFilter={resetFilter}
              />
            )}

            {filter.type === "range" && (
              <CatalogRangeFilter
                filter={filter}
                filters={filters}
                onChangeFilters={onChangeFilters}
              />
            )}
          </VerticalLayout>
        ))}
    </VerticalLayout>
  );
};

export default CatalogFilters;
