import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  HorizontalLayout,
  Icon,
  Link,
  SectionZone,
  Text,
  VerticalLayout,
  VerticalSeparator,
} from "@fleet.co/tarmac";
import {
  faChevronDown,
  faChevronUp,
  faExclamationCircle,
  faMinus,
  faPlus,
  faTrash,
  faTruck,
} from "@fortawesome/pro-regular-svg-icons";

import { useUserContext } from "src/contexts/UserContext";
import useDeviceType from "src/hooks/useDeviceType";
import useDataLayer from "src/hooks/useDataLayer";

import { hasExtendedDeliveryDelay } from "src/tools/DeviceHelpers";
import { getSpecificationValueLabel, productSpecs } from "src/tools/ProductHelpers";

import MoneyFormat from "src/tools/MoneyFormat";
import styles from "./ShoppingCartModalItem.module.scss";

const typologyTitles = {
  new: "Neuf",
  asnew: "Certifié Apple",
  refurb: "Reconditionné +",
};

const ShoppingCartModalItem = (props) => {
  const {
    device,
    deviceNumber,
    productId,
    keyboard,
    orderInfo,
    reloadOrderInfo,
    reloadOrder,
  } = props;
  const { t } = useTranslation();
  const { user } = useUserContext();
  const defaultAddress = user.company.addresses.find((a) => a.is_default);
  const deviceType = useDeviceType();
  const { trackAddToCart, trackRemoveFromCart } = useDataLayer();

  const [isExpanded, setIsExpanded] = useState(false);

  const addToShoppingCart = useCallback(async () => {
    const newDevice = {
      product_id: productId,
      shipping_address_id: defaultAddress?.id,
      ...(keyboard && keyboard !== "null" && { keyboard_layout: keyboard }),
    };

    try {
      await user.api.addDeviceToOrder(orderInfo.id, newDevice);
      // Add same product
      trackAddToCart(device.product, orderInfo);
      reloadOrderInfo();
      reloadOrder();
    } catch (e) {
      console.error(e);
    }
  }, [
    reloadOrderInfo,
    reloadOrder,
    orderInfo,
    user.api,
    productId,
    keyboard,
    defaultAddress,
    trackAddToCart,
    device,
  ]);

  const removeFromShoppingCart = useCallback(async () => {
    try {
      await user.api.removeDeviceFromOrder(orderInfo.id, { device });
      reloadOrderInfo();
      reloadOrder();
    } catch (e) {
      console.error(e);
    }
  }, [reloadOrderInfo, reloadOrder, orderInfo.id, user.api, device]);

  const removeGroupDeviceFromCart = useCallback(async () => {
    try {
      await user.api.deleteOrderProduct(orderInfo.id, {
        product_id: productId,
        ...(keyboard && keyboard !== "null" && { keyboard_layout: keyboard }),
      });

      trackRemoveFromCart(device.product, orderInfo);
      reloadOrderInfo();
      reloadOrder();
    } catch (e) {
      console.error(e);
    }
  }, [reloadOrderInfo, reloadOrder, orderInfo, device, user.api, productId, keyboard, trackRemoveFromCart]);

  const getToastContent = useCallback(() => {
    if (!hasExtendedDeliveryDelay(keyboard)) {
      return {
        variant: "action",
        icon: faTruck,
        label: t("Délais de livraison : {{shippingMin}} à {{shippingMax}} jours ouvrés", {
          shippingMin: device.product.min_shipping,
          shippingMax: device.product.max_shipping,
        }),
      };
    }

    return {
      variant: "warning",
      icon: faExclamationCircle,
      label: t("Les claviers étrangers peuvent impacter les délais de livraison"),
    };
  }, [device.product.max_shipping, device.product.min_shipping, keyboard, t]);

  const toastContent = getToastContent();

  const companyDiscount = user.getCompanyDiscount(device.base_price);

  const finalPrice = Number(device.rent) + (companyDiscount?.offer_type === "MONTH_OFFERED" ? Number(device.base_price) / 36 : 0);
  const basePrice = finalPrice + (companyDiscount?.offer_type === "CURRENCY_DISCOUNT" ? Number(companyDiscount?.offer_value) : 0);

  return (
    <SectionZone>
      <VerticalLayout gap={16}>
        <HorizontalLayout gap={16} flexWrap={deviceType !== "desktop" ? "wrap" : "nowrap"}>
          <img
            loading="lazy"
            src={device?.product?.img_url}
            alt={device?.name}
            className={styles.image}
          />

          <VerticalLayout gap={4}>
            <Text bold>{t(device?.name)}</Text>
            <HorizontalLayout gap={8} alignItems="baseline">
              <Text bold>
                <MoneyFormat value={finalPrice.toFixed(2)} />
              </Text>
              <HorizontalLayout gap={4}>
                {companyDiscount?.offer_type === "CURRENCY_DISCOUNT" && (
                  <Text size="XS" lineThrough muted>
                    <MoneyFormat value={basePrice} />
                  </Text>
                )}
                <Text size="XS" muted>{t("/mois")}</Text>
              </HorizontalLayout>
            </HorizontalLayout>
            {companyDiscount?.offer_type === "MONTH_OFFERED" && (
              <HorizontalLayout gap={8}>
                <Text size="S" variant="accent" bold>{t("Offre active : 1 mois offert")}</Text>
              </HorizontalLayout>
            )}
            <VerticalLayout gap={8}>
              <Link onClick={() => setIsExpanded(!isExpanded)}>
                <HorizontalLayout gap={8} alignItems="center">
                  {t("Afficher les détails")}
                  <Icon size="S" icon={isExpanded ? faChevronUp : faChevronDown} />
                </HorizontalLayout>
              </Link>

              {isExpanded && (
                <VerticalLayout gap={8} style={{ paddingLeft: 8 }}>
                  {productSpecs[device.category].map((spec) => device[spec.key] && (
                    <HorizontalLayout gap={4} flexWrap="wrap">
                      <Text bold size="XS">
                        {`${t(spec.label)} :`}
                      </Text>
                      <Text size="XS">{t(getSpecificationValueLabel(spec.key, device[spec.key]))}</Text>
                    </HorizontalLayout>
                  ))}

                  {device.product.has_keyboard && device.keyboard_layout && (
                    <HorizontalLayout gap={4} flexWrap="wrap">
                      <Text bold size="XS">
                        {`${t("Type de clavier")} :`}
                      </Text>
                      <Text size="XS">
                        {t(device.keyboard_layout)}
                      </Text>
                    </HorizontalLayout>
                  )}

                  <HorizontalLayout gap={4} flexWrap="wrap">
                    <Text bold size="XS">
                      {`${t("État")} :`}
                    </Text>
                    <Text size="XS">
                      {t(typologyTitles[device.quality])}
                    </Text>
                  </HorizontalLayout>
                </VerticalLayout>
              )}

              <HorizontalLayout gap={8} alignItems="center" className={styles.btnContainer}>
                <HorizontalLayout className={styles.productActionsContainer}>
                  <Button
                    data-cy="decrementQuantity"
                    small
                    variant="ghost"
                    onClick={removeFromShoppingCart}
                  >
                    <Icon icon={faMinus} size="S" />
                  </Button>
                  <VerticalSeparator />
                  <Text textAlign="center" className={styles.devicesCount}>
                    {deviceNumber}
                  </Text>
                  <VerticalSeparator />
                  <Button small variant="ghost" onClick={addToShoppingCart} data-cy="incrementQuantity">
                    <Icon icon={faPlus} size="S" />
                  </Button>
                </HorizontalLayout>
                <Button
                  data-cy="removeGroup"
                  small
                  variant="ghost"
                  onClick={removeGroupDeviceFromCart}
                >
                  <Icon icon={faTrash} size="S" />
                </Button>
              </HorizontalLayout>
            </VerticalLayout>
          </VerticalLayout>
        </HorizontalLayout>

        {device.product.has_keyboard && (
          <HorizontalLayout gap={8} className={`${styles.shippingToast} ${styles[toastContent.variant]}`}>
            <Icon icon={toastContent.icon} variant={toastContent.variant} size="S" />
            <Text variant={toastContent.variant}>
              {toastContent.label}
            </Text>
          </HorizontalLayout>
        )}
      </VerticalLayout>
    </SectionZone>
  );
};

export default ShoppingCartModalItem;
