import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,

  HorizontalLayout, ListCard, Modal, Text, TextField, VerticalLayout,
} from "@fleet.co/tarmac";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";

import tagColorsAndNames from "src/data/tagColorsAndNames.json";

import styles from "./ShoppingListModal.module.scss";

const ShoppingListModal = (props) => {
  const {
    shoppingList, onClose, onSave,
  } = props;

  const { user: currentUser } = useUserContext();
  const { addToast } = useToastContext();

  const { t } = useTranslation();

  const [currentShoppingList, setCurrentShoppingList] = useState(null);

  useEffect(() => {
    if (shoppingList) {
      setCurrentShoppingList({ ...shoppingList });
    }
  }, [shoppingList]);

  const handleChange = (event) => {
    setCurrentShoppingList({ ...currentShoppingList, name: event.target.value });
  };

  const handleClick = (tagColor) => {
    setCurrentShoppingList(({ ...currentShoppingList, color: tagColor }));
  };

  const onModalClose = async () => {
    if (currentShoppingList.name.length === 0) {
      addToast(t("La liste d'achat doit obligatoirement avoir un titre "));

      return;
    }

    try {
      if (shoppingList) await currentUser.api.updateShoppingList(shoppingList.id, currentShoppingList);
      onSave();
      onClose();
    } catch (err) {
      addToast(t("La modification de la liste d'achat a échoué"));
    }
  };

  const primaryButton = (
    <Button fullWidth onClick={onModalClose}>
      {t("Modifier")}
    </Button>
  );

  const secondaryButton = (
    <Button fullWidth onClick={onClose}>
      {t("Annuler")}
    </Button>
  );

  return (

    <Modal
      closeModal={onClose}
      title={t("Modifier la liste d'achat")}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
    >
      <VerticalLayout gap={8} isScrollable>

        <VerticalLayout gap="8" fullWidth>
          <HorizontalLayout gap="8" justifyContent="space-between">
            <VerticalLayout gap={16} fullWidth>
              <Text>{t("Titre")}</Text>
              <TextField
                onChange={(e) => handleChange(e)}
                value={currentShoppingList ? currentShoppingList.name : shoppingList.name}
              />
            </VerticalLayout>
          </HorizontalLayout>
          <VerticalLayout gap={16}>
            <Text>{t("Couleur")}</Text>
            {tagColorsAndNames.map((c, index) => (
              <ListCard
                key={index}
                onClick={() => handleClick(c.value)}
                className={styles.colorCard}
                isActive={currentShoppingList ? c.value === currentShoppingList.color : c.value === shoppingList.color}
              >
                <HorizontalLayout gap="16">
                  <div className={styles.colorIcon} style={{ backgroundColor: c.color }} />
                  {t(c.french_name)}
                </HorizontalLayout>
              </ListCard>
            ))}
          </VerticalLayout>
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default ShoppingListModal;
