import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {
  Button, Heading, HorizontalLayout, LabeledTextField, Text, VerticalLayout,
} from "@fleet.co/tarmac";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import User from "src/tools/User";
import AuthService from "src/components/auth/auth-service";
import useLocalStorage from "src/hooks/useLocalStorage";

import styles from "./TokenReset.module.scss";

const TokenReset = (props) => {
  const { match } = props;
  const { updateUser } = useUserContext();
  const { addToast } = useToastContext();
  const [, setAccessToken] = useLocalStorage("accessToken");

  const { t } = useTranslation();
  const history = useHistory();
  const [infos, setInfos] = useState({
    email: "",
    password: "",
    password_repeat: "",
  });
  const service = new AuthService();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (infos.password !== infos.password_repeat) {
      addToast(t("Les mots de passe ne correspondent pas"));

      return;
    }

    service
      .validateToken({ userId: match.params.id, password: infos.password, token: match.params.token })
      .then((response) => {
        const { accessToken, ...userInfo } = response;

        setAccessToken(accessToken);

        const user = new User();

        Object.assign(user, userInfo);
        user.load();
        updateUser(user);

        if (!user.hasRole("ADMIN") && !user.checkIfCompanyIntegrationisActive("EMPLOYEE_ACCESS")) {
          addToast(t("Ce compte n'a pas accès au Cockpit, contactez votre responsable commercial pour la création d'accès supplémentaires."));
        } else {
          history.push("/");
        }
      })
      .catch(() => {
        addToast(t("Ce lien n'est plus valide. Veuillez recommencer la procédure."));
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setInfos({ ...infos, [name]: value });
  };

  return (
    <HorizontalLayout gap="24" justifyContent="space-between" className={styles.mainWrapper}>
      <VerticalLayout gap="16">
        <Heading>{t("Réinitialisation du mot de passe")}</Heading>
        <Text>
          <Trans>
            Bienvenue sur votre
            {" "}
            <span className="isPrimary">Cockpit</span>
            .
            <br />
            Veuillez choisir votre nouveau mot de
            passe.
          </Trans>
        </Text>

        <VerticalLayout gap="8">
          <LabeledTextField
            name="password"
            type="password"
            placeholder={t("Nouveau mot de passe")}
            indication="*"
            value={infos.password}
            onChange={handleChange}
          >
            {t("Nouveau mot de passe")}
          </LabeledTextField>
          <LabeledTextField
            name="password_repeat"
            type="password"
            placeholder={t("Confirmation nouveau mot de passe")}
            indication="*"
            value={infos.password_repeat}
            onChange={handleChange}
          >
            {t("Confirmation nouveau mot de passe")}
          </LabeledTextField>
        </VerticalLayout>
        <Button onClick={handleSubmit}>{t("Confirmer")}</Button>

      </VerticalLayout>
      <figure className={styles.image}>
        <img src="/images/high_five.png" alt="changement mot de passe" />
      </figure>
    </HorizontalLayout>

  );
};

export default TokenReset;
