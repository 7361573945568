import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Alert, Button, Label, Modal, Text, VerticalLayout,
} from "@fleet.co/tarmac";

import DeviceSelectableListCard from "src/components/cockpit/blocks/listCards/DeviceSelectableListCard";
import TagsAttribution from "src/components/cockpit/blocks/tags/TagsAttribution";
import TagModification from "src/components/cockpit/blocks/tags/TagModification";
import { useUserContext } from "src/contexts/UserContext";

import styles from "./TaggingAllModal.module.scss";

const TaggingAllModal = (props) => {
  // PROPS & CONTEXT
  const {
    onSave, onClose, selectedDevices,
  } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();

  // GENERAL STATES
  const [errors, setErrors] = useState("");

  // INITIALISATION STATES
  const [selectedDevicesTags, setSelectedDevicesTags] = useState([]);
  const [tagInfo, setTagInfo] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // FUNCTIONS
  const onCloseTaggingModal = () => {
    setErrors("");
    onClose();
  };

  const onSaveConfirmTagging = () => {
    const tagIds = selectedDevicesTags.map((t) => t.id);
    const devicePatch = { new_tags: tagIds };

    try {
      Promise.all(
        selectedDevices.map((device) => currentUser.api.modifyDevice(device.id, devicePatch)),
      );
      setSelectedDevicesTags([]);
      setErrors(null);
      onSave();
      onClose();
    } catch (err) {
      setErrors(err.response.data.message);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onSaveConfirmModifying = async () => {
    try {
      if (Object.keys(tagInfo).length) await currentUser.api.updateTag(tagInfo.id, tagInfo);
      onSave();
      onClose();
    } catch (err) {
      setErrors(err.response.data.message);
    }
  };

  const deviceNumber = selectedDevices.length;
  const deviceWording = deviceNumber > 1 ? "appareils" : "appareil";

  const primaryButton = isMenuOpen ? (
    <Button fullWidth onClick={onSaveConfirmModifying}>
      {t("Modifier le tag")}
    </Button>
  ) : (
    <Button fullWidth onClick={onSaveConfirmTagging}>
      {t("Enregistrer ")}
    </Button>
  );

  const secondaryButton = isMenuOpen ? (
    <Button fullWidth onClick={closeMenu}>
      {t("Annuler")}
    </Button>
  ) : (
    <Button fullWidth onClick={onClose}>
      {t("Fermer")}
    </Button>
  );

  return (
    <Modal
      closeModal={onCloseTaggingModal}
      title={t("Assigner un tag")}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
    >
      <VerticalLayout gap={24} isScrollable>
        {errors && <Alert onClick={() => setErrors("")}>{errors}</Alert>}
        { !isMenuOpen && (
          <VerticalLayout>
            <Text>
              <Trans>
                Vos tags seront affectés ajoutés à
                {" "}
                {selectedDevices.length}
                {" "}
                <span className={styles.textColor}>
                  {" "}
                  {{ deviceNumber }}
                  {" "}
                  {deviceWording}
                </span>
              </Trans>
            </Text>

            <VerticalLayout gap={8}>
              {selectedDevices.map((d, i) => (
                <DeviceSelectableListCard
                  key={i}
                  device={d}
                  canHover={false}
                  canSelect={false}
                />
              ))}
            </VerticalLayout>
          </VerticalLayout>
        )}

        <VerticalLayout fullWidth isScrollable>
          {!isMenuOpen ? (
            <>
              <Label>{t("Tags")}</Label>
              <TagsAttribution
                device={{}}
                tagInfo={tagInfo}
                onChange={(tags) => setSelectedDevicesTags(tags)}
                setTagInfo={setTagInfo}
                toggleMenu={toggleMenu}
                isEditable
              />
            </>
          ) : (
            <TagModification
              closeMenu={closeMenu}
              getTagColor={() => tagInfo.color}
              tag={tagInfo}
              setTagInfo={setTagInfo}
            />
          )}
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default TaggingAllModal;
