import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@fleet.co/tarmac";
import { faCircleSmall } from "@fortawesome/pro-solid-svg-icons";

import styles from "./DeviceStatus.module.scss";

const DeviceStatus = ({ device }) => {
  let label; let
    statusClassName;
  const { t } = useTranslation();

  if (!device) return null;

  if (device.status === "WAITING") {
    label = "En traitement";
    statusClassName = styles.statusInProgress;
  } else if (device.status === "PURCHASED") {
    label = "En préparation";
    statusClassName = styles.statusInProgress;
  } else if (device.status === "SHIPPED") {
    label = "En livraison";
    statusClassName = styles.statusInProgress;
  } else if (
    ["DELIVERED", "BDL_SENT", "RECEIVED", "RUNNING"].includes(device.status)
    && !["SAV", "OUT_OF_SERVICE", "SPARE"].includes(device.usage_status)
    && (device.end_of_life_status !== "DONE" || device.renewal_type === "BUYBACK")
  ) {
    label = "En service";
    statusClassName = styles.statusRunning;
  } else if (device.usage_status === "SAV") {
    label = "En maintenance";
    statusClassName = styles.statusUnderMaintenance;
  } else if (device.usage_status === "OUT_OF_SERVICE" || (device.end_of_life_status === "DONE" && device.renewal_type !== "BUYBACK")) {
    label = "Hors service";
    statusClassName = styles.statusOutOfService;
  } else if (device.usage_status === "SPARE") {
    label = "Prêt à l'usage";
    statusClassName = styles.statusRunning;
  }

  return (
    <span className={`${styles.statusWrapper} ${statusClassName}`}>
      <Icon icon={faCircleSmall} size="s" />
      {" "}
      {t(label)}
    </span>
  );
};

export default DeviceStatus;
