import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HorizontalLayout,
  Icon,
  Label,
  Link,
  Search,
  Text,
  VerticalLayout,
} from "@fleet.co/tarmac";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import EmployeeSelectableListCard from "src/components/cockpit/blocks/listCards/EmployeeSelectableListCard";
import { useUserContext } from "src/contexts/UserContext";
import { employeeMatches } from "src/tools/SearchFilters";

import styles from "./UserSearch.module.scss";

const UserSearch = (props) => {
  const {
    selectedUser, setSelectedUser, onAdd, label,
  } = props;
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const { t } = useTranslation();

  const { user: currentUser } = useUserContext();
  const [searchUser, setSearchUser] = useState("");

  const searchUsers = (search) => {
    if (search) {
      const filtered = currentUser.company.employees.filter(
        (employee) => employeeMatches(employee, search),
      );

      setFilteredUsersList(filtered);
    } else {
      setFilteredUsersList(currentUser.company.employees);
    }
  };

  return (

    <>
      <VerticalLayout gap="4">
        <Label>{t(label)}</Label>
        <HorizontalLayout justifyContent="space-between">
          <Search
            value={searchUser}
            onChange={(e) => {
              searchUsers(e.target.value);
              setSearchUser(e.target.value);
            }}
            placeholder={t("Rechercher un employé")}
            style={{ width: "100%" }}
          />
          {
            searchUser.length !== 0
            && <Icon icon={faTimes} onClick={() => setSearchUser("")} className={styles.timesIcon} />
          }
        </HorizontalLayout>

        {onAdd && (
        <HorizontalLayout justifyContent="space-between">
          <Text>{t("Pas dans la liste ?")}</Text>
          <Link onClick={onAdd}>{t("Ajouter un employé")}</Link>
        </HorizontalLayout>
        )}

      </VerticalLayout>
      {selectedUser ? (
        <HorizontalLayout gap="8" alignItems="center" className={styles.cardWrapper}>
          <EmployeeSelectableListCard
            noCheckbox
            employee={selectedUser}
            grow
            isActive
            noscroll
          />
          <Icon icon={faTimes} onClick={() => setSelectedUser(null)} className={styles.timesIcon} />
        </HorizontalLayout>
      ) : (
        <VerticalLayout gap="16" className={styles.listWrapper}>
          {filteredUsersList
            .map((e) => (
              <EmployeeSelectableListCard
                noCheckbox
                onCardClick={() => setSelectedUser(e)}
                employee={e}
              />
            ))}
        </VerticalLayout>
      )}
    </>
  );
};

export default UserSearch;
