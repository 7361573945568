import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button, HorizontalLayout, Icon, Modal, Text, VerticalLayout,
} from "@fleet.co/tarmac";
import { faChevronDown, faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import LoadingSpinner from "src/components/LoadingSpinner";
import FormatDate from "src/components/FormatDate";
import { useUserContext } from "src/contexts/UserContext";

import styles from "./DeliveryTrackingModal.module.scss";

const DeliveryTrackingModal = ({ onClose, device }) => {
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [displayContactInformation, setDisplayContactInformation] = useState(false);
  const [shipmentInfo, setShipmentInfo] = useState();

  const deviceIsDelivered = (device.status === "DELIVERED" || device.status === "RECEIVED" || device.status === "BDL_SENT");
  const showPromisedDeliveryDate = Boolean(device.promised_delivery_date);
  const showEstimatedDeliveryDate = Boolean(device.expected_delivery_date);
  const showExactDeliveryDate = Boolean(device.delivery_date);

  const displayStatus = () => {
    if (deviceIsDelivered) {
      return t("Votre commande a été livrée.");
    }

    if (!device.aftership_tag) {
      // No tag
      return t("Votre commande est en cours de préparation");
    }

    if (device.aftership_tag === "Delivered") {
      return t("Votre commande a été livrée.");
    }

    if (device.aftership_tag === "AvailableForPickup") {
      return t("Votre commande est disponible en point de retrait");
    }

    // All other tags
    return t("Votre commande est en transit.");
  };

  const displayBreadcrumb = (checkpoint, checkpointIndex) => {
    if (checkpoint.tag === "Delivered") {
      // Final checkpoint
      return <div className={styles.deliveryStatus__breadcrumb__finished} />;
    }

    if (checkpointIndex === 0) {
      // Latest checkpoint
      return <div className={styles.deliveryStatus__breadcrumb__current} />;
    }

    if (checkpointIndex !== 0) {
      // All other checkpoints
      return <div className={styles.deliveryStatus__breadcrumb__passed} />;
    }

    return null;
  };

  useEffect(() => {
    setIsLoading(true);
    currentUser.api
      .getDeviceTrackingInfo(device.id)
      .then((apiResponse) => {
        setShipmentInfo(apiResponse.data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <Modal
      title={t("Suivi de livraison")}
      closeModal={onClose}
      secondaryButton={(
        <Button onClick={onClose}>
          {t("Fermer")}
        </Button>
      )}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <section>
          <div className={styles.primaryInformation}>
            <div className={styles.primaryInformation__container}>
              <div style={{ marginRight: "30px" }}>
                <h3>{t("Statut de livraison")}</h3>
                <p className={styles.primaryInformation__text}>{displayStatus()}</p>
              </div>
              {!deviceIsDelivered && (
                <div>

                  {showExactDeliveryDate && (
                    <>
                      <h3>{t("Date de livraison")}</h3>
                      <p className={styles.primaryInformation__text_bold}>
                        <span>{new Date(device.delivery_date).getUTCDate()}</span>
                        {" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {new Date(device.delivery_date).toLocaleDateString("default", {
                            month: "short",
                          })}
                        </span>
                      </p>
                    </>
                  )}

                  {!showExactDeliveryDate && showEstimatedDeliveryDate && (
                    <>
                      <h3>{t("Livraison estimée")}</h3>
                      <p className={styles.primaryInformation__text_bold}>
                        <span>{new Date(device.expected_delivery_date).getUTCDate()}</span>
                        {" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {new Date(device.expected_delivery_date).toLocaleDateString("default", {
                            month: "short",
                          })}
                        </span>
                      </p>
                    </>
                  )}

                  {!showExactDeliveryDate && !showEstimatedDeliveryDate && showPromisedDeliveryDate && (
                    <>
                      <h3>{t("Livraison estimée")}</h3>
                      <p className={styles.primaryInformation__text_bold}>
                        <span>{new Date(device.promised_delivery_date).getUTCDate()}</span>
                        {" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {new Date(device.promised_delivery_date).toLocaleDateString("default", {
                            month: "short",
                          })}
                        </span>
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
            <hr />

            <div className={styles.primaryInformation__container}>
              <VerticalLayout fullWidth>
                <HorizontalLayout justifyContent="space-between" fullWidth>
                  <VerticalLayout alignItems="flex-start">
                    <Text size="M" bold>{t("Livreur")}</Text>
                    <Text size="S" muted>{device.carrier || "--"}</Text>
                  </VerticalLayout>
                  <VerticalLayout alignItems="flex-end">
                    <Text size="M" bold>{t("N° de suivi")}</Text>
                    <Text size="S" muted>{device.tracking_number || "--"}</Text>
                  </VerticalLayout>
                </HorizontalLayout>
                <HorizontalLayout justifyContent="space-between" fullWidth>
                  <VerticalLayout alignItems="flex-start">
                    <Text size="M" bold>{t("Code de livraison")}</Text>
                    <Text size="S" muted>{device.delivery_code || "--"}</Text>
                  </VerticalLayout>
                  <VerticalLayout alignItems="flex-end">
                    <Text size="M" bold>{t("Date d'achat")}</Text>
                    <Text size="S" muted><FormatDate date={device.purchase_date || device.created_at} /></Text>
                  </VerticalLayout>
                </HorizontalLayout>
              </VerticalLayout>
            </div>
          </div>

          <div
            onClick={() => setDisplayContactInformation(!displayContactInformation)}
            className={styles.additionalInformation}
            aria-hidden
          >
            <div>{t("Indication de livraison")}</div>
            <div>
              {displayContactInformation ? (
                <Icon icon={faChevronDown} />
              ) : (
                <Icon icon={faChevronRight} />
              )}
            </div>
          </div>
          {displayContactInformation && (
            <div className={styles.contactDetails}>
              <div>
                <p>{t("Contact livraison :")}</p>
                <p style={{ fontWeight: "bold" }}>
                  {device.address.firstName}
                  {" "}
                  {device.address.lastName}
                </p>
              </div>
              <div>
                <strong>
                  {device.address.address1 || device.address.address2}
                  {" "}
                  {device.address.zip}
                  {" "}
                  {device.address.city}
                </strong>
              </div>
            </div>
          )}

          <div className={styles.deliveryStatus}>
            {shipmentInfo && shipmentInfo.length
              ? shipmentInfo.map((checkpoint, index) => {
                const checkpointDate = new Date(checkpoint.checkpoint_time);

                return (
                  <div key={index} className={styles.deliveryStatus__container}>
                    <div className={styles.deliveryStatus__date}>
                      <p>
                        <span>{checkpointDate.getUTCDate()}</span>
                        {" "}
                        <span className={styles.deliveryStatus__month}>
                          {checkpointDate.toLocaleDateString("default", {
                            month: "short",
                          })}
                        </span>
                      </p>
                      <p>
                        {checkpointDate.toLocaleTimeString("fr-FR", {
                          hour12: false,
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </p>
                    </div>

                    <div
                      className={
                        index !== shipmentInfo.length - 1
                        && styles.deliveryStatus__breadcrumb
                      }
                    >
                      {displayBreadcrumb(checkpoint, index)}
                    </div>

                    <div className={styles.deliveryStatus__address}>
                      <p>{checkpoint.location}</p>
                      <p style={{ wordBreak: "break-word" }}>{checkpoint.message}</p>
                      <p style={{ wordBreak: "break-word" }}>{checkpoint.location}</p>
                    </div>
                  </div>
                );
              })
              : ""}
          </div>
        </section>
      )}
    </Modal>
  );
};

export default DeliveryTrackingModal;
