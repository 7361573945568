import React from "react";
import { Redirect, Route } from "react-router-dom";

import { useUserContext } from "src/contexts/UserContext";

import NoRoute from "src/components/routing/NoRoute";
import LoadingSpinner from "src/components/LoadingSpinner";

const SpecialRoute = (props) => {
  const { user: currentUser } = useUserContext();

  const {
    component: Component,
    accessControl,
    children,
    ...rest
  } = props;

  // User not yet fetched
  if (currentUser === null) return <LoadingSpinner />;

  const auth = accessControl();

  if (!auth.isAuthorized) {
    return auth.redirectTo ? <Redirect to={auth.redirectTo} /> : <NoRoute />;
  }

  // Component passed to route : render it
  // No component passed : render children
  return <Route {...rest}>{Component ? <Component /> : children}</Route>;
};

export default SpecialRoute;
