import { useUserContext } from "src/contexts/UserContext";

const MoneyFormat = (props) => {
  const { value } = props;
  const { user: currentUser } = useUserContext();

  const currency = currentUser.getCompanyCurrency();
  const priceInNumber = parseFloat(value);
  const price = priceInNumber.toLocaleString(currency === "EUR" ? "fr" : "en", {
    style: "currency",
    currency,
    narrowSymbol: true,
  });

  return price;
};

export default MoneyFormat;
