import { useEffect, useState } from "react";

function getDeviceType() {
  if (window.innerWidth < 768) {
    return "mobile";
  }

  if (window.innerWidth < 1025) {
    return "tablet";
  }

  return "desktop";
}

export default function useDeviceType() {
  const [deviceType, setDeviceType] = useState(getDeviceType());

  useEffect(() => {
    function handleResize() {
      setDeviceType(getDeviceType());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return deviceType;
}
