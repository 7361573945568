import { useTranslation } from "react-i18next";
import API from "src/tools/API";
import { useHistory } from "react-router-dom";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import useLocalStorage from "src/hooks/useLocalStorage";

const useRegistration = (onError) => {
  const [, setAccessToken] = useLocalStorage("accessToken");
  const { reloadUser } = useUserContext();
  const { addToast } = useToastContext();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const register = async (newUser, source) => {
    const userData = {
      ...newUser,
      ...(source || {}),
    };

    const accessToken = window.localStorage.getItem("accessToken");

    userData.language = userData.language || i18n.language;

    const createUserConfig = {
      url: "/register",
      method: "post",
      data: userData,
      ...(accessToken && {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    };

    // Track e-mail through HS Cookie
    // eslint-disable-next-line
    const hsCookie = (window._hsq = window._hsq || []);

    try {
      const response = await API.request(createUserConfig);

      if (response.data.accessToken) {
        setAccessToken(response.data.accessToken);
      }
      hsCookie.push(["identify", { email: newUser.email.trim() }]);
      window.dataLayer.push({ event: "registration_submit" });

      await reloadUser();
      await history.push("/catalog");
    } catch (err) {
      addToast(t(err.response.data.message));

      await onError(err.response.data);
    }
  };

  return { register };
};

export default useRegistration;
