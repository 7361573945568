import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-locize-backend";
import { locizePlugin } from "locize";
import locizeLastUsed from "locize-lastused";

const isDev = process.env.NODE_ENV === "development";

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
  // YOU should not expose your apps API key to production!!!
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
};

if (isDev) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  i18n.use(locizeLastUsed);
}

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(locizePlugin)
  .init({
    debug: false,
    fallbackLng: "fr",
    referenceLng: "fr",
    saveMissing: isDev, // you should not use saveMissing in production
    updateMissing: isDev,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: locizeOptions,
    locizeLastUsed: {
      ...locizeOptions,
      debounceSubmit: 90000,
      allowedHosts: ["localhost"],
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "",
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
      useSuspense: true,
    },
  });

export default i18n;
