export const hasExtendedDeliveryDelay = (keyboard) => {
  const fastDeliveryKeyboard = ["FR - Français - AZERTY", "ES - Espagnol - QWERTY", "DE - Allemand - QWERTZ", "UK - Anglais - QWERTY"];

  return !fastDeliveryKeyboard.includes(keyboard);
};

export const devicesOSTypesMatching = {
  macos: "MacOS",
  windows: "Windows",
  linux: "Linux",
  ipados: "iPadOS",
  android: "Android",
  ios: "iOS",
};
