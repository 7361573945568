import React, { useState } from "react";
import { HorizontalLayout, VerticalLayout } from "@fleet.co/tarmac";

import useDeviceType from "src/hooks/useDeviceType";

import ResetPassword from "./sections/ResetPassword";
import LoginBase from "./sections/LoginBase";
import styles from "./CockpitLogin.module.scss";
import CImage from "../cockpit/blocks/CImage";

const Login = () => {
  const deviceType = useDeviceType();

  const [resetPassword, setResetPassword] = useState(false);

  return (
    <HorizontalLayout className={styles.loginCockpit}>
      <VerticalLayout
        gap={24}
        alignItems="center"
        justifyContent="center"
        fullHeight
        fullWidth
        className={styles.leftPart}
      >
        <CImage label="fleet_new_logo_gkkgrz" width={140} />
        {resetPassword
          ? (
            <ResetPassword backToLogin={() => setResetPassword(false)} />
          ) : (
            <LoginBase goToResetPassword={() => setResetPassword(true)} />
          )}
      </VerticalLayout>

      {deviceType === "desktop" && (
        <VerticalLayout justifyContent="center" className={styles.rightPart} fullHeight fullWidth>
          {resetPassword ? (
            <figure className="image">
              <img loading="lazy" src="/images/website/not_eligible_desktop.svg" alt="Forgotten password" />
            </figure>
          ) : (
            <figure className="image">
              <img loading="lazy" src="/images/first_connection.png" alt="Login" />
            </figure>
          )}
        </VerticalLayout>
      )}
    </HorizontalLayout>
  );
};

export default Login;
