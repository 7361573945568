import axios from "axios";

let baseUrl = "";

if (process.env.REACT_APP_PR_NUMBER) {
  baseUrl = `https://fleet-co-pr-${process.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
} else {
  baseUrl = process.env.REACT_APP_APIURL;
}

export default axios.create({
  baseURL: `${baseUrl || "/v1"}`,
});
