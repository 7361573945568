import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HorizontalLayout, Link, Text } from "@fleet.co/tarmac";

import useLocalStorage from "src/hooks/useLocalStorage";
import { useUserContext } from "src/contexts/UserContext";

const HasAccountAlready = (props) => {
  const { withoutArrow } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [, , clearAccessToken] = useLocalStorage("accessToken");
  const { reloadUser } = useUserContext();

  const goToLogin = useCallback(async () => {
    clearAccessToken();
    await reloadUser();
    history.push("/login");
  }, [clearAccessToken, reloadUser, history]);

  return (
    <HorizontalLayout gap={16} justifyContent="center">
      <Text muted>{t("Déjà un compte ?")}</Text>
      <Link name="Login" variant="accent" onClick={goToLogin}>
        {t("Se connecter")}
        {!withoutArrow && <img src="/images/fp_login_arrow.svg" alt="" style={{ paddingLeft: 16 }} />}
      </Link>
    </HorizontalLayout>
  );
};

export default HasAccountAlready;
