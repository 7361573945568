// components/auth/auth-service.js

import axios from "axios";
import User from "src/tools/User";

class AuthService {
  constructor() {
    this.api_prefix = "/auth";
    this.load();
  }

  load = () => {
    const accessToken = window.localStorage.getItem("accessToken");
    let baseUrl = "";

    if (process.env.REACT_APP_PR_NUMBER) {
      baseUrl = `https://fleet-co-pr-${process.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
    } else {
      baseUrl = process.env.REACT_APP_APIURL;
    }

    this.api = axios.create({
      baseURL: `${baseUrl || "/v1"}`,
      ...(accessToken && {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    });
  };

  login = (email, password) => this.api.post(`${this.api_prefix}/login`, { email, password })
    .then(({ data: { accessToken, ...userInfo } }) => {
      window.localStorage.setItem("accessToken", accessToken);

      const user = new User();

      Object.assign(user, userInfo);

      return user.load();
    }).catch((err) => {
      throw err;
    });

  loggedin = () => this.api.get(`${this.api_prefix}/current`)
    .then((response) => {
      const user = new User();

      Object.assign(user, response.data);
      user.load();

      return user;
    });

  logout = () => this.api.get(`${this.api_prefix}/logout`, {}).then((response) => {
    if (response.data) {
      window.localStorage.setItem("accessToken", response.data.accessToken);
      window.location.reload();

      return true;
    }

    window.localStorage.removeItem("accessToken");

    return false;
  }).catch((err) => {
    throw err;
  });

  requestMagicLink = (email) => this.api.post(`${this.api_prefix}/request_magic_link`, { email }).then(
    (response) => response.data,
  );

  requestToken = (email) => this.api.post(`${this.api_prefix}/request_token`, { email }).then(
    (response) => response.data,
  );

  validateToken = ({ userId, password, token }) => this.api.post(`${this.api_prefix}/validate_token`, {
    user_id: userId,
    password,
    token,
  }).then((response) => response.data);

  reset = (password) => this.api.post(`${this.api_prefix}/resetpass`, { password }).then(
    (response) => response.data,
  );
}

export default AuthService;
