import React from "react";
import { useTranslation } from "react-i18next";
import {
  Label,
  LabeledTextField,
  VerticalLayout,
} from "@fleet.co/tarmac";

import PhoneInput from "src/components/utils/PhoneInput";
import InsuranceInfoForm from "./InsuranceInfoForm";

const CreateSignatory = (props) => {
  const { newUser, setNewUser, setIsPhoneValid } = props;
  const { t } = useTranslation();

  const onInputChange = (e) => {
    const { name, value } = e.target;

    setNewUser({ ...newUser, [name]: value });
  };

  const onPhoneChange = (phone, country) => {
    setNewUser({ ...newUser, phone, phone_country: country });
  };

  const onChange = (name, value) => {
    setNewUser((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  return (
    <VerticalLayout gap="16">
      <LabeledTextField onChange={onInputChange} name="firstName" value={newUser.firstName} indication="*">
        {t("Prénom")}
      </LabeledTextField>
      <LabeledTextField onChange={onInputChange} name="lastName" value={newUser.lastName} indication="*">
        {t("Nom")}
      </LabeledTextField>
      <LabeledTextField onChange={onInputChange} name="email" value={newUser.email} indication="*">
        {t("E-Mail")}
      </LabeledTextField>
      <VerticalLayout>
        <Label indication="*">{t("Numéro de téléphone")}</Label>
        <PhoneInput onChange={onPhoneChange} country={newUser.phone_country} value={newUser.phone} setIsPhoneValid={setIsPhoneValid} indication="*" />
      </VerticalLayout>
      <InsuranceInfoForm signatoryInfos={newUser} onChange={onChange} />
    </VerticalLayout>
  );
};

export default CreateSignatory;
