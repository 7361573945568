import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Heading,
  HorizontalLayout,
  HorizontalSeparator,
  LabeledTextField,
  Link,
  Text,
  VerticalLayout,
} from "@fleet.co/tarmac";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import AuthService from "src/components/auth/auth-service";

import GoogleConnect from "src/components/common/GoogleConnect";
import useLocalStorage from "src/hooks/useLocalStorage";
import LoginEmailSent from "./LoginEmailSent";

import styles from "../CockpitLogin.module.scss";

const LoginBase = (props) => {
  const { goToResetPassword } = props;
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { updateUser } = useUserContext();
  const [inputLogin, setInputLogin] = useState({ email: "", password: "" });
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [accessToken, setAccessToken] = useLocalStorage("accessToken");
  const [loginEmailSent, setLoginEmailSent] = useState(false);
  const service = new AuthService();

  useEffect(() => {
    const hasMagicLink = new URLSearchParams(search).get("magicLink");
    const email = new URLSearchParams(search).get("email");

    // E-mail in params : send link
    if (email) {
      service.requestMagicLink(email).then(() => setLoginEmailSent(true));
      setLoginEmailSent(true);
      history.replace("/login");
    } else if (hasMagicLink) {
      setLoginEmailSent(true);
      history.replace("/login");
    }
  }, [search]);

  const redirectToRegister = (err) => {
    if (err.response.status === 403) {
      history.push(`/register${inputLogin.email ? `?email=${encodeURIComponent(inputLogin.email)}` : ""}`);
      addToast(t(err.response.data.message || "Une erreur est survenue lors de la connexion"));
    } else {
      console.error(err);
      addToast(t(err.response.data.message || "Une erreur est survenue lors de la connexion"));
    }
  };

  const handleSubmit = async () => {
    try {
      const user = await service.login(inputLogin.email, inputLogin.password);

      updateUser(user);
    } catch (err) {
      redirectToRegister(err);
    }
  };

  const handleSubmitMagicLink = async () => {
    try {
      await service.requestMagicLink(inputLogin.email);
      setLoginEmailSent(true);
    } catch (err) {
      redirectToRegister(err);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setInputLogin({ ...inputLogin, [name]: value });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (showPasswordInput) {
        handleSubmit();
      } else {
        handleSubmitMagicLink();
      }
    }
  };

  useEffect(() => {
    const urlQuery = new URLSearchParams(search);
    const token = urlQuery.get("token");
    const error = urlQuery.get("error");
    const success = urlQuery.get("success");

    if (token) {
      // Get Token from popup query, send to window opener & close popup
      try {
        window.opener.getInfoFromPopup(token);
      } catch (err) {
        console.error(err);
      } finally {
        window.close();
      }
    } else if (error) {
      try {
        window.opener.getInfoFromPopup(null, error);
      } catch (err) {
        console.error(err);
      } finally {
        window.close();
      }
    } else if (success && success === "true") {
      window.opener.location.href = "/launchpad";
      window.close();
    }
  });

  window.getInfoFromPopup = (token, error) => {
    // Get Token from Popup
    if (token) {
      setAccessToken(token);
    } else if (error) {
      switch (error) {
        case "AccessDenied":
          addToast(t("Vous devez être administrateur Google Workspace pour effecteur cette action"));
          break;
        case "Unauthorized":
          addToast(t("Veuillez valider la connexion avec votre compte Google"));
          break;
        default:
          addToast(t("Une erreur est survenue"));
          break;
      }
    }
  };

  const onConnectGoogle = () => {
    let baseUrl;

    if (process.env.REACT_APP_PR_NUMBER) {
      baseUrl = `https://fleet-co-pr-${process.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
    } else {
      baseUrl = process.env.REACT_APP_APIURL;
    }

    window.open(
      `${baseUrl}/auth/google${accessToken ? `?accessToken=${accessToken}` : ""}`,
      "Continuer avec Google",
      "height=600,width=450",
    );
  };

  return (loginEmailSent ? <LoginEmailSent /> : (
    <>
      <Heading textAlign="center" component="h1" size="L">
        <Trans>
          Se connecter au
          {" "}
          <span className="isPrimary">Cockpit !</span>
        </Trans>
      </Heading>

      <VerticalLayout gap={16} alignItems="center" className={styles.formContainer}>
        <GoogleConnect onConnect={onConnectGoogle} />
        <HorizontalLayout gap={16} fullWidth alignItems="center">
          <HorizontalSeparator />
          <Text size="XS" muted>{t("Ou")}</Text>
          <HorizontalSeparator />
        </HorizontalLayout>
        <VerticalLayout gap={16} alignItems="center" className={styles.formInput}>
          <LabeledTextField
            name="email"
            type="text"
            placeholder={t("Email")}
            indication="*"
            value={inputLogin.email}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          >
            {t("E-mail professionnel")}
          </LabeledTextField>

          {showPasswordInput && (
            <LabeledTextField
              name="password"
              type="password"
              placeholder={t("Mot de passe")}
              indication="*"
              value={inputLogin.password}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            >
              {t("Mot de passe")}
            </LabeledTextField>
          )}

          <Button
            type="submit"
            variant="primary"
            onClick={showPasswordInput ? handleSubmit : handleSubmitMagicLink}
          >
            {t(showPasswordInput ? "Se connecter" : "Se connecter avec un email")}
          </Button>
          {/* <Button onClick={anonymousLogin}>Demo</Button> */}
        </VerticalLayout>

        <Link data-cy="primaryLink" onClick={showPasswordInput ? goToResetPassword : () => setShowPasswordInput(true)}>
          {t(showPasswordInput ? "Mot de passe oublié" : "Se connecter avec un mot de passe")}
        </Link>
      </VerticalLayout>

      <HorizontalLayout
        gap={16}
        alignItems="center"
        justifyContent="center"
        switchToColumnInMobile
        className={styles.signInPart}
      >
        <Text size="S" muted>{t("Pas encore de compte ?")}</Text>
        <Link
          variant="accent"
          onClick={() => (history.push("/register"))}
        >
          {t("S'inscrire gratuitement")}
          <img src="/images/green-arrow-right.svg" alt="arrow" />
        </Link>
      </HorizontalLayout>
    </>
  ));
};

export default LoginBase;
