import React from "react";
import { useTranslation } from "react-i18next";
import { HorizontalLayout, Text, TextField } from "@fleet.co/tarmac";
import { faEuroSign, faPoundSign } from "@fortawesome/pro-regular-svg-icons";
import { useUserContext } from "src/contexts/UserContext";

const CatalogRangeFilter = (props) => {
  const { filter, filters, onChangeFilters } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const currencyLogo = currentUser.getCompanyCurrency() === "GBP" ? faPoundSign : faEuroSign;

  return (
    <HorizontalLayout gap={8} data-cy="filterOptions">
      <HorizontalLayout gap={8} alignItems="center">
        <Text size="XS">{t("de")}</Text>
        <TextField
          type="number"
          placeholder="Min"
          value={filters[`${filter.key}Min`] || ""}
          onChange={(e) => onChangeFilters("priceRangeMin", e.target.value)}
          icon={currencyLogo}
        />
      </HorizontalLayout>

      <HorizontalLayout gap={8} alignItems="center">
        <Text size="XS">{t("à")}</Text>
        <TextField
          type="number"
          placeholder="Max"
          value={filters[`${filter.key}Max`] || ""}
          onChange={(e) => onChangeFilters("priceRangeMax", e.target.value)}
          icon={currencyLogo}
        />
      </HorizontalLayout>
    </HorizontalLayout>
  );
};

export default CatalogRangeFilter;
