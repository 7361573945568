const getTicketLabel = (ticket) => {
  const ticketCategory = ticket?.category;

  const categoryLabels = [
    { value: "ORDER__UPDATE", label: "Modifier ma commande" },
    { value: "ORDER__SHIPPING", label: "Adresse et livraison" },
    { value: "CONTRACT__INFORMATION", label: "Contrats en cours" },
    { value: "CONTRACT__RENEWAL", label: "Retour et fin de contract" },
    { value: "CONTRACT__INVOICE", label: "Facture et prélévements" },
    { value: "INSURANCE__LOST", label: "Assurance, vol" },
    { value: "INSURANCE__BROKEN", label: "Assurance casse" },
    { value: "TECHNICAL_PROBLEM", label: "Problème technique" },
    { value: "MDM", label: "Mobile Device Management" },
    { value: "COCKPIT", label: "Usage Cockpit" },
  ];

  return categoryLabels.find((cl) => cl.value === ticketCategory)?.label || "Question sur ma commande";
};

export default getTicketLabel;
