import React from "react";
import { AdvancedImage } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

const CImage = (props) => {
  const {
    label, width, fetchUrl, alt, ...rest
  } = props;
  const cld = new Cloudinary({ cloud: { cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME } });

  // Instantiate and configure a CloudinaryImage object.
  let img;

  if (fetchUrl) {
    img = cld.image(fetchUrl).setDeliveryType("fetch");
  } else {
    img = cld.image(label);
  }

  return <AdvancedImage cldImg={img} width={width} alt={alt || ""} {...rest} />;
};

export default CImage;
