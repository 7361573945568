import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import {
  Button,
  Heading,
  HorizontalLayout,
  HorizontalSeparator,
  Icon,
  Label,
  LabeledTextField,
  Select,
  Text,
  VerticalLayout,
} from "@fleet.co/tarmac";

import { Trans, useTranslation } from "react-i18next";
import PhoneInput from "src/components/utils/PhoneInput";
import { useHistory, useLocation } from "react-router-dom";
import useRegistration from "src/hooks/useRegistration";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { useToastContext } from "src/contexts/ToastContext";
import getLocalePlaceholder from "src/components/utils/getLocalePlaceholder";
import HasAccountAlready from "./HasAccountAlready";
import styles from "./RegistrationForm.module.scss";
import CImage from "../blocks/CImage";

const RegistrationForm = (props) => {
  const { source } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { addToast } = useToastContext();
  const { search } = useLocation();

  const defaultEmail = new URLSearchParams(search).get("email");

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: defaultEmail || "",
    phone: "",
    company_size: "",
    phone_country: "",
  });

  const [, setIsPhoneValid] = useState(Boolean(userInfo?.phone));
  const [isLoading, setIsLoading] = useState(false);

  const onError = useCallback((responseData) => {
    if (responseData.redirect_to) {
      const redirectUrl = new URL(responseData.redirect_to);

      history.push(redirectUrl.pathname + redirectUrl.search);
    }
  }, [history]);

  const { register } = useRegistration(onError);

  const onChange = useCallback((e) => {
    const { name, value } = e.target;

    setUserInfo({ ...userInfo, [name]: value });
  }, [userInfo]);

  const onPhoneChange = useCallback((phone, country) => {
    setUserInfo({ ...userInfo, phone, phone_country: country });
  }, [userInfo]);

  const onSave = useCallback(async () => {
    // check if every field exept company_size of userInfo is not equals to ""
    const isEveryFieldFilled = Object.keys(userInfo).every((key) => userInfo[key] !== "");

    if (!isEveryFieldFilled) {
      addToast("Veuillez remplir tous les champs suivis d'une *");

      return;
    }

    setIsLoading(true);
    await register(userInfo, source);
    setIsLoading(false);
  }, [addToast, register, source, userInfo]);

  const companySizeOptions = useMemo(
    () => [
      {
        label: "1 - 10 employés",
        value: "1-10",
        selected: userInfo.company_size === "1-10",
      },
      {
        label: "11 - 25 employés",
        value: "11-25",
        selected: userInfo.company_size === "11-25",
      },
      {
        label: "26 - 50 employés",
        value: "26-50",
        selected: userInfo.company_size === "26-50",
      },
      {
        label: "Plus de 50 employés",
        value: "50+",
        selected: userInfo.company_size === "50+",
      },
    ],
    [userInfo.company_size],
  );

  const onSelectChange = useCallback((name, value) => {
    setUserInfo({ ...userInfo, [name]: value });
  }, [userInfo]);

  useEffect(() => {
    if (i18n?.language) {
      setUserInfo({ ...userInfo, phone_country: i18n.language === "en" ? "gb" : i18n.language });
    }
  }, [i18n.language]);

  // add event listener to save the form when the user press enter
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        onSave();
      }
    };

    document.addEventListener("keydown", handleKeyPress, false);

    return () => {
      document.removeEventListener("keydown", handleKeyPress, false);
    };
  }, [onSave]);

  return (
    <VerticalLayout
      className={`${styles.registrationWrapper} ${styles.bgImg}`}
      fullWidth
      isScrollable
      justifyContent="center"
      alignItems="center"
    >
      <HorizontalLayout
        className={styles.registrationPopUp}
        switchToColumnInMobile
      >
        <VerticalLayout className={styles.registrationBenefits} justifyContent="space-around">
          <VerticalLayout className={styles.logoContainer}>
            <CImage label="fleet_new_logo_gkkgrz" />
          </VerticalLayout>
          <VerticalLayout gap={16}>
            <Heading component="h2" size="M">
              {t("Gérez vos équipements simplement")}
            </Heading>
            <Text size="s" className={styles.textBenefits}>
              {t("Une solution tout-en-un pour l’acquisition, la gestion et le renouvellement de vos équipements :")}
            </Text>
            <VerticalLayout gap={8}>
              <HorizontalLayout gap={16}>
                <Icon icon={faCheck} size="S" variant="accent" />
                <Text size="s">{t("Ordinateurs et mobilier haut de gamme")}</Text>
              </HorizontalLayout>
              <HorizontalLayout gap={16}>
                <Icon icon={faCheck} size="S" variant="accent" />
                <Text size="s">{t("Garantie illimitée et support dédié")}</Text>
              </HorizontalLayout>
              <HorizontalLayout gap={16}>
                <Icon icon={faCheck} size="S" variant="accent" />
                <Text size="s">{t("Livraison rapide partout en Europe")}</Text>
              </HorizontalLayout>
              <HorizontalLayout gap={16}>
                <Icon icon={faCheck} size="S" variant="accent" />
                <Text size="s">{t("Une plateforme unique pour tout gérer")}</Text>
              </HorizontalLayout>
            </VerticalLayout>
          </VerticalLayout>
          <VerticalLayout gap={16} className={styles.contactQuestions}>
            <Text size="s">{t("Des questions ?")}</Text>
            <Text size="s">
              <a target="_new" href={`${process.env.REACT_APP_WEBSITEURL}/${i18n.language}/demo`} className={styles.textContact}>
                {t("Contacter un expert Fleet")}
                {" "}
              </a>
            </Text>
          </VerticalLayout>
        </VerticalLayout>

        <VerticalLayout className={styles.registrationForm} gap={32}>
          <Heading component="h1" size="M" textAlign="center">
            {t("Créez votre compte Fleet gratuitement")}
          </Heading>
          <VerticalLayout gap={16}>
            <LabeledTextField
              onChange={onChange}
              value={userInfo.firstName || ""}
              name="firstName"
              placeholder={getLocalePlaceholder(i18n.language, "firstName")}
              indication="*"
            >
              {t("Prénom")}
            </LabeledTextField>
            <LabeledTextField
              onChange={onChange}
              value={userInfo.lastName || ""}
              name="lastName"
              placeholder={getLocalePlaceholder(i18n.language, "lastName")}
              indication="*"
            >
              {t("Nom")}
            </LabeledTextField>
            <LabeledTextField
              onChange={onChange}
              value={userInfo.email || ""}
              name="email"
              placeholder={getLocalePlaceholder(i18n.language, "email")}
              indication="*"
            >
              {t("E-mail professionnel")}
            </LabeledTextField>
            <VerticalLayout>
              <Label
                indication="*"
              >
                {t("Numéro de téléphone")}
              </Label>
              <PhoneInput
                value={userInfo.phone}
                onChange={onPhoneChange}
                country={userInfo.phone_country}
                setIsPhoneValid={setIsPhoneValid}
              />
            </VerticalLayout>
            <VerticalLayout>
              <Label
                indication="*"
              >
                {t("Taille de votre entreprise")}
              </Label>
              <Select
                dataCy="companySize"
                onSelect={(option) => onSelectChange("company_size", option.value)}
                options={companySizeOptions.map((option) => ({ ...option, label: t(option.label) }))}
                placeholder={t("Veuillez sélectionner")}
              />
            </VerticalLayout>
            <Text size="xs" muted>
              <Trans>
                En soumettant ce formulaire, je confirme que j'ai lu la
                {" "}
                <a
                  target="_new"
                  href={`${process.env.REACT_APP_WEBSITEURL}/${i18n.language}/privacy-gdpr`}
                  className={styles.privacyPolicyLink}
                >
                  politique de confidentialité
                </a>
                {" "}
                et que j'accepte le traitement de mes données personnelles par Fleet.
              </Trans>
            </Text>
            <Button
              data-track="click_register"
              fullWidth
              variant="accent"
              isLoading={isLoading}
              onClick={onSave}
            >
              {t("S'inscrire")}
            </Button>
          </VerticalLayout>
          <VerticalLayout>
            <HorizontalLayout gap={16} fullWidth alignItems="center">
              <HorizontalSeparator />
              <Text size="m" muted>{t("Ou")}</Text>
              <HorizontalSeparator />
            </HorizontalLayout>
          </VerticalLayout>
          <VerticalLayout>
            <HasAccountAlready withoutArrow />
          </VerticalLayout>
        </VerticalLayout>
      </HorizontalLayout>
    </VerticalLayout>
  );
};

export default RegistrationForm;
