import React from "react";
import { useTranslation } from "react-i18next";

const FormatDate = (props) => {
  const { i18n } = useTranslation();
  const { date, withTime } = props;

  if (date === null) {
    return <>-</>;
  }

  const dateObj = new Date(date);
  // const options = { year: 'numeric', month: 'short', day: 'numeric' };
  let dateFormatted;

  dateFormatted = dateObj.toLocaleDateString("fr-FR");

  if (withTime) {
    if (i18n.language === "en") {
      dateFormatted
        += ` ${
          dateObj.toLocaleTimeString("en", {
            hour: "2-digit",
            minute: "2-digit",
          })}`;
    } else {
      dateFormatted
        += ` ${
          dateObj.toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          })}`;
    }
  }

  return dateFormatted;
};

export default FormatDate;
