import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Modal, VerticalLayout } from "@fleet.co/tarmac";

import { useUserContext } from "src/contexts/UserContext";
import { useModalContext } from "src/contexts/ModalContext";

import MissingSerialNumberDialog from "src/components/dialogs/missingSerialNumber/MissingSerialNumberDialog";
import EmployeeDeviceManagement from "./EmployeeDeviceManagement";

const UserDevicesModal = (props) => {
  // PROPS & CONTEXT
  const { onSave, onClose, selectedEmployees } = props;
  const { user } = useUserContext();
  const { t } = useTranslation();
  const history = useHistory();
  const { setModal } = useModalContext();
  const { user: currentUser } = useUserContext();
  const [employeeDevicesAttribution, setEmployeeDevicesAttribution] = useState(
    selectedEmployees.reduce((acc, cur) => (
      { ...acc, [cur.id]: cur.used_devices.map((d) => d.id) }
    ), {}),
  );
  const [missingSerialNumberModal, setMissingSerialNumberModal] = useState(false);
  const [deviceWithoutSerialNumber, setDeviceWithoutSerialNumber] = useState(undefined);

  // FUNCTIONS
  const onConfirmModal = async () => {
    for (const employee of selectedEmployees) {
      await user.api.modifyUser(employee.id, {
        used_devices: employeeDevicesAttribution[employee.id],
      });
    }
    onSave();
    if (deviceWithoutSerialNumber) {
      setMissingSerialNumberModal(true);
    } else {
      onClose();
    }
  };

  const goToReorder = () => {
    setModal(null);
    history.push("/catalog");
  };

  return (
    <Modal
      closeModal={onClose}
      title={t("Gérer les équipements")}
      primaryButton={(
        <Button fullWidth onClick={onConfirmModal}>
          {t("Terminer")}
        </Button>
      )}
      secondaryButton={currentUser.company.country !== "Other" && (
        <Button fullWidth onClick={goToReorder}>
          {t("Nouvelle location")}
        </Button>
      )}
    >
      {missingSerialNumberModal && (
        <MissingSerialNumberDialog
          setOpen={onClose}
          device={deviceWithoutSerialNumber}
          onSave={onSave}
        />
      )}
      <VerticalLayout gap={16} isScrollable fullHeight>
        {selectedEmployees.map((employee, index) => (
          <EmployeeDeviceManagement
            employee={employee}
            index={index}
            deviceAttribution={employeeDevicesAttribution}
            updateDeviceAttribution={setEmployeeDevicesAttribution}
            setDeviceWithoutSerialNumber={setDeviceWithoutSerialNumber}
          />
        ))}
      </VerticalLayout>
    </Modal>
  );
};

export default UserDevicesModal;
