import React, {
  Suspense, lazy, useEffect, useState,
} from "react";

import { Helmet } from "react-helmet";

import {
  Route,
  Switch, useHistory, useLocation,
} from "react-router-dom";

import qs from "qs";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { useTranslation } from "react-i18next";
import CockpitRoute from "src/components/routing/CockpitRoute";
import AuthRoute from "src/components/routing/AuthRoute";

import ToastContainer from "src/components/common/ToastContainer";
import ConfirmModalContainer from "src/components/modals/ConfirmModalContainer";

import Modals from "src/components/modals/Modals";

import LoadingSpinner from "src/components/LoadingSpinner";

import LoginMagic from "src/components/login/LoginMagic";
import Login from "src/components/login/Login";
import TokenReset from "src/components/login/TokenReset";
import { ConfirmModalProvider } from "src/contexts/ConfirmModalContext";
import { ModalProvider } from "src/contexts/ModalContext";
import { useToastContext } from "src/contexts/ToastContext";
import { useUserContext } from "src/contexts/UserContext";
import { CurrentOrderProvider } from "src/contexts/CurrentOrderContext";
import useLocalStorage from "src/hooks/useLocalStorage";
import i18n from "./i18n";

import styles from "./App.module.scss";
import RegistrationForm from "./components/cockpit/registration/RegistrationForm";

const Cockpit = lazy(() => import(/* webpackChunkName: "Cockpit" */ "src/components/cockpit"));

const freePaths = ["^/login", "^/token-reset/*", "^/register"];

const App = () => {
  const [source, setSource] = useState({
    offer_code: null,
    referral: null,
    utm_term: null,
    utm_campaign: null,
    utm_source: null,
    google_client_id: null,
    utm_medium: null,
    gclid: null,
    landing_page: null,
    referrer: null,
    language: null,
    leaseback: null,
    after_life: null,
  });
  const { user, reloadUser } = useUserContext();
  const { i18n: i18nConfig, t } = useTranslation();
  const { toasts, closeToast } = useToastContext();
  const [, setAccessToken] = useLocalStorage("accessToken");
  const history = useHistory();
  const sourceParams = qs.parse(window.location.search.slice(1)) || {};

  const location = useLocation();

  useEffect(() => {
    const parsedParams = { ...sourceParams };

    // Store in session Storage if user is a fleet user logged as client => impact Navbar
    if (sourceParams.accessToken) {
      setAccessToken(sourceParams.accessToken);
      delete parsedParams.accessToken;
    }

    if (!sourceParams.referrer) {
      sourceParams.referrer = document.referrer;
    }

    if (!sourceParams.landing_page) {
      sourceParams.landing_page = window.location.pathname;
    }

    if (sourceParams) {
      const stateParams = Object.keys(source)
        // If the state param is in the URL
        .filter((key) => Object.prototype.hasOwnProperty.call(sourceParams, key))
        // Create a key-value pair
        .map((key) => [key, sourceParams[key]]);

      // Object.entity turns it into an object
      setSource({ ...source, ...Object.fromEntries(stateParams) });
    }

    // Clean Params
    const url = window.location.pathname + parsedParams ? `?${qs.stringify(parsedParams)}` : "";

    history.replace(url);
  }, []);

  useEffect(() => {
    const applyOffer = async () => {
      if (user && source.offer_code) {
        const addedOffer = await user?.api.addNewOfferToCompany({ offerCode: source.offer_code });

        reloadUser();

        return addedOffer;
      }

      return null;
    };

    applyOffer();
    // user.id en dependance pour que le useEffect ne se charge qu'au premier chargement du user et pas a chaque update
  }, [user?.id, source.offer_code]);

  useEffect(() => {
    // Wait for user & source parsing
    const hasSourceLanguage = sourceParams.language !== undefined && sourceParams.language !== "";
    // Source param exists but has not been parsed into the `source` state yet
    const sourceLanguageNotParsed = hasSourceLanguage && !(source.language === sourceParams.language);

    // User null (!= false)  means login endpoint has not returned yet
    // If either action is not done, do not check language
    if (user === null || sourceLanguageNotParsed) return;

    const isOnPublicPage = freePaths.some((p) => location.pathname.match(new RegExp(p)));

    if (user && user.isLoaded() && user.language) {
      if (i18n.language !== user.language) {
        i18n.changeLanguage(user.language);
      }

      return;
    }

    // No user : update language depending on params
    if (isOnPublicPage) {
      const shortNavLanguage = navigator.language.substring(0, 2);
      const supportedLangs = {
        fr: "fr",
        en: "en",
        de: "de",
        es: "es",
      };

      // Map 2-letter language code to code used by Fleet
      const formattedNavLanguage = supportedLangs[shortNavLanguage];

      // If source language is defined : use it instead
      const selectedLanguage = source.language ? source.language : formattedNavLanguage;

      // All else failes : use english
      i18nConfig.changeLanguage(selectedLanguage || "en");
    }
  }, [user, source, location.pathname]);

  useEffect(() => {
    // Redirect old onboardings to new registration page
    if (location.pathname.match(/^\/onboarding/)) {
      history.replace("/register");
    }
  }, [location.pathname]);

  return (
    i18nConfig.language // Check language so it rerender on lang change
    && (
      <Suspense fallback={<LoadingSpinner />}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <ModalProvider>
            <ConfirmModalProvider>
              <CurrentOrderProvider>
                <Helmet>
                  <title>
                    Fleet ⎢
                    {t("Location d'ordinateurs pour votre entreprise")}
                  </title>
                  <meta
                    property="og:description"
                    content={t("💻 Facilitez la gestion de votre matériel informatique d'entreprise avec Fleet : votre ordinateur professionnel est livré en 72h et 100% assuré.")}
                  />
                  <meta
                    name="description"
                    content={t("💻 Facilitez la gestion de votre matériel informatique d'entreprise avec Fleet : votre ordinateur professionnel est livré en 72h et 100% assuré.")}
                    data-react-helmet="true"
                  />
                </Helmet>

                <div>
                  <div className={styles.toastStyle}>
                    {toasts?.map((toast) => (
                      <ToastContainer toast={toast} closeToast={closeToast} />
                    ))}
                  </div>

                  <Switch>
                    <AuthRoute
                      path="/register"
                      render={() => (
                        <Suspense fallback={<LoadingSpinner />}>
                          <RegistrationForm source={source} />
                        </Suspense>
                      )}
                    />

                    <AuthRoute
                      path="/login"
                      render={() => (
                        <Suspense fallback={<LoadingSpinner />}>
                          <div className="cockpitContainer">
                            <Login />
                          </div>
                        </Suspense>
                      )}
                    />

                    <Route
                      path="/login-magic"
                      render={() => (
                        <Suspense fallback={<LoadingSpinner />}>
                          <div className="cockpitContainer">
                            <LoginMagic />
                          </div>
                        </Suspense>
                      )}
                    />

                    <AuthRoute
                      path="/token-reset/:id/:token"
                      render={(routeProps) => (
                        <Suspense fallback={<LoadingSpinner />}>
                          <div className="cockpitContainer">
                            <TokenReset match={routeProps.match} />
                          </div>
                        </Suspense>
                      )}
                    />

                    {/* Cockpit is default router */}
                    <CockpitRoute
                      path="/"
                      otherCountryAccess
                      render={() => (
                        <Suspense fallback={<LoadingSpinner />}>
                          <div className="cockpitContainer">
                            <Cockpit source={source} />
                          </div>
                        </Suspense>
                      )}
                    />
                  </Switch>

                  <Modals />
                  <ConfirmModalContainer />
                </div>
              </CurrentOrderProvider>
            </ConfirmModalProvider>
          </ModalProvider>
        </GoogleOAuthProvider>
      </Suspense>
    )
  );
};

export default App;
