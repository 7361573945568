import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button, Modal,
} from "@fleet.co/tarmac";

import UserSearch from "src/components/common/UserSearch";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";

import SignatoryChoice from "./SignatoryChoice";
import CreateSignatory from "./CreateSignatory";

const OrderSignatoryModal = (props) => {
  const {
    onClose, onFinish, order, isOrderMonitoring,
  } = props;
  const { t } = useTranslation();
  const { user: currentUser, reloadUser } = useUserContext();
  const { addToast } = useToastContext();
  const initialUser = useMemo(() => ({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phone_country: "fr",
    cityOfBirth: "",
    countryOfBirth: "",
    dateOfBirth: null,
  }), []);
  const [step, setStep] = useState("choice");
  const [selectedUser, setSelectedUser] = useState(null);
  const [signatory, setSignatory] = useState(null);
  const [modalConfig, setModalConfig] = useState(
    { mainButton: null, secondaryButton: null, title: null },
  );
  const [isNew, setIsNew] = useState(false);
  const [newUser, setNewUser] = useState(initialUser);
  const [signatoryInfos, setSignatoryInfos] = useState({
    phone: null, phone_country: null, cityOfBirth: "", dateOfBirth: null, countryOfBirth: "",
  });
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isNewPhoneValid, setIsNewPhoneValid] = useState(Boolean(newUser?.phone));
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const getSignatoryInfo = useCallback(async (signatoryId) => {
    let fetchedSignatory;

    try {
      fetchedSignatory = await currentUser.api.getUser(signatoryId);
    } catch (e) {
      // si le signataire de la commande a été supprimé
      fetchedSignatory = await currentUser.api.getUser(currentUser.id);
    }
    setSignatory(fetchedSignatory.data);
    setSignatoryInfos({
      phone: fetchedSignatory.data.phone,
      phone_country: fetchedSignatory.data.phone_country,
      cityOfBirth: fetchedSignatory.data.city_of_birth,
      countryOfBirth: fetchedSignatory.data.country_of_birth,
      dateOfBirth: fetchedSignatory.data.date_of_birth,
    });
  }, [currentUser.api, currentUser.id]);

  const submitNewignatory = useCallback(async () => {
    setIsLoading(true);
    const userData = {
      firstName: newUser.firstName,
      lastName: newUser.lastName,
      phone: newUser.phone,
      phone_country: newUser.phone_country,
      email: newUser.email,
      city_of_birth: newUser.cityOfBirth,
      country_of_birth: newUser.countryOfBirth,
      date_of_birth: newUser.dateOfBirth,
      roles_add: "ADMIN",
    };

    const mandatoryFields = ["email", "firstName", "lastName", "phone", "phone_country"];

    if (mandatoryFields.some((field) => !userData[field])) {
      addToast(t("Veuillez remplir tous les champs"), "error");
      setIsLoading(false);

      return;
    }

    if (!isNewPhoneValid) {
      addToast(t("Le numéro de téléphone n'est pas valide"), "error");
      setIsLoading(false);

      return;
    }

    setIsNew(true);
    try {
      const newSignatory = await currentUser.api.addUser(userData);

      await getSignatoryInfo(newSignatory.data.id);
      setStep("choice");
      setNewUser(initialUser);
      reloadUser();
    } catch (e) {
      addToast(t("Une erreur est survenue lors de la création de l'employée"));
    }
    setIsLoading(false);
  }, [addToast, currentUser.api, getSignatoryInfo, initialUser, newUser, t, reloadUser, isNewPhoneValid]);

  const upgradeEmployee = useCallback(async () => {
    await getSignatoryInfo(selectedUser.id);
    setStep("choice");
    setIsNew(true);
  }, [getSignatoryInfo, selectedUser]);

  const submitRole = useCallback(async () => {
    const userData = {};
    const isLR = Boolean(signatory?.roles.find((r) => r.role === "LEGAL_REPRESENTATIVE"));

    if (isLR) {
      userData.roles_del = ["LEGAL_REPRESENTATIVE"];
    } else {
      userData.roles_add = "LEGAL_REPRESENTATIVE";
    }
    await currentUser.api.modifyUser(signatory.id, userData);
    getSignatoryInfo(signatory.id);
  }, [currentUser.api, getSignatoryInfo, signatory]);

  const submitSignatory = useCallback(async () => {
    if (!isPhoneValid) {
      addToast(t("Le numéro de téléphone n'est pas valide"), "error");
      setIsLoading(false);

      return;
    }

    const mandatoryFields = ["phone"];

    if (mandatoryFields.some((field) => !signatoryInfos[field])) {
      addToast(t("Tous les champs requis doivent être remplis"), "error");
      setIsLoading(false);

      return;
    }

    await currentUser.api.modifyOrder(order.id, { signatory_id: signatory.id });
    await currentUser.api.modifyUser(signatory.id, {
      phone: signatoryInfos.phone,
      phone_country: signatoryInfos.phone_country,
      city_of_birth: signatoryInfos.cityOfBirth,
      country_of_birth: signatoryInfos.countryOfBirth,
      date_of_birth: signatoryInfos.dateOfBirth,
    });
    onFinish(signatory.id);
    onClose();
  }, [currentUser.api, onClose, onFinish, order, signatory, signatoryInfos, isPhoneValid]);

  useEffect(() => {
    let mainButton;
    let title;
    let secondaryButton;

    const isLR = signatory?.roles.find((r) => r.role === "LEGAL_REPRESENTATIVE");
    const isDisabled = !isLR && currentUser.company.country === "Spain";

    if (!isOrderMonitoring) {
      secondaryButton = (
        <Button fullWidth onClick={onClose}>
          {t("Plus tard")}
        </Button>
      );
    }

    if (step === "choice") {
      mainButton = (
        <Button fullWidth onClick={submitSignatory} disabled={isDisabled}>
          {t("Valider")}
        </Button>
      );
      title = "Choisir le signataire";
    } else if (step === "add") {
      mainButton = (
        <Button fullWidth onClick={upgradeEmployee}>
          {t("Valider")}
        </Button>
      );
      title = "Ajouter un signataire";
    } else {
      mainButton = (
        <Button fullWidth onClick={submitNewignatory} disabled={isLoading}>
          {t("Valider")}
        </Button>
      );
      title = "Créer un employé";
    }
    setModalConfig({ mainButton, secondaryButton, title });
  }, [step, signatory, selectedUser, newUser, isOrderMonitoring, isLoading, signatoryInfos, submitNewignatory, submitSignatory, onClose, t, upgradeEmployee]);

  useEffect(() => {
    getSignatoryInfo(order.signatory_id);
    if (history.location.pathname.includes("onboarding")) setIsNew(true);
  }, []);

  return (
    <Modal
      closeModal={onClose}
      title={t(modalConfig.title)}
      primaryButton={modalConfig.mainButton}
      secondaryButton={modalConfig.secondaryButton}
    >
      {step === "choice" && (
        <SignatoryChoice
          setStep={setStep}
          submitRole={submitRole}
          signatoryInfos={signatoryInfos}
          setSignatoryInfos={setSignatoryInfos}
          setIsPhoneValid={setIsPhoneValid}
          isNew={isNew}
          signatory={signatory}
          getSignatoryInfo={getSignatoryInfo}
          isOrderMonitoring={isOrderMonitoring}
        />
      )}
      {step === "add" && (
        <UserSearch
          onAdd={() => setStep("create")}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          label="Qui va signer le contrat ?"
        />
      )}
      {step === "create" && (
        <CreateSignatory
          newUser={newUser}
          setNewUser={setNewUser}
          setIsPhoneValid={setIsNewPhoneValid}
        />
      )}
    </Modal>
  );
};

export default OrderSignatoryModal;
