import React, { useEffect, useRef } from "react";

const OutsideClickEffect = (ref, action) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, action]);
};

const OutsideClickHandler = (props) => {
  const { action, style, children } = props;
  const wrapperRef = useRef(null);

  OutsideClickEffect(wrapperRef, action, style);

  return (
    <div style={style} ref={wrapperRef}>
      {children}
    </div>
  );
};

export default OutsideClickHandler;
